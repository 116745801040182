import axios from "axios";
import { OBJECT_TYPE_SLIDE, OBJECT_TYPE_TERMINAL } from "../constants/ObjectTypes";
import { clearAccount, getAccount, getTerminal, isLoggedIn } from "./SessionManager";
import Compressor from 'compressorjs';
import { SKIP_IMAGE_COMPRESSION } from "../app/Config";
import Util, { isV2 } from "../util/Util";
import UIUtil from "../util/UIUtil";

class Api {

    lang;
    headers = {};
    showLogoutMsg = true;

    /**
     * 
     * @param {(api: Api, listener: function) => void} method 
     * @param {boolean} silentError 
     * @returns {Promise<[boolean, object]>} 
     */
    async try(method, silentError, silentSuccess) {
        try {
            const response = await new Promise((resolve, reject) => method(this, response => {
                if (response.status === true) {
                    resolve(response.payload)
                } else {
                    reject({ roboErpApiErr: true, msg: response.message })
                }
            }));
            if (!silentSuccess) {
                UIUtil.showSuccess()
            }
            return [true, response]
        } catch (e) {
            if (!silentError) {
                UIUtil.showError(e?.roboErpApiErr ? e.msg : "");
            }
            return [false, e];
        }
    }

    auth(emailAddress, password, listener) {
        this.post('/api/public/auth/login', [], { emailAddress, password }, listener);
    }

    isLoggedIn(listener) {
        this.get('/api/auth/is-logged-in', [], listener)
    }

    logout(listener) {
        this.get('/api/auth/logout', [], listener)
    }

    async asyncLogout() {
        return new Promise((resolve, reject) => this.logout(response => {
            if (response.status === true) {
                resolve(response.payload)
            } else {
                reject({ roboErpApiErr: true, msg: response.message })
            }
        }))
    }

    /**
     * @param {{page: number, requestObjectType: number, sortRequest: { property: string, asc: boolean }}} pagedLoadRequest
     */
    getList(pagedLoadRequest, objectType, listener) {
        this.post('/api/list/get-list', [objectType], pagedLoadRequest, listener);
    }

    /**
     * @param {{requestType: number, objectType: number, params: any}} request 
     */
    getCustomList(request, listener) {
        this.post('/api/list/get-custom-list', [], request, listener);
    }

    /**
     * @param {{parentId: number, childType: number}} request 
     */
    getChildren(request, listener) {
        this.post('/api/list/get-children', [], request, listener);
    }

    /**
     * @param {{relationType: number, objectType: number, objectProperty: string, parentType: number, parentId: number, childType: number, childId: number, showNotIncluded: boolean, manyToManyShowChild: boolean}} request 
     */
    getTableRelationList(request, listener) {
        this.post('/api/list/get-table-relation-list', [], request, listener);
    }

    /**
     * @param {{relationType: number, objectType: number, objectProperty: string, parentType: number, parentId: number, childType: number, childId: number, idsToAdd: Array<number>, idsToRemove: Array<Number>, manyToManySaveChild: boolean}} request 
     */
    saveTableRelationRequest(request, listener) {
        this.post('/api/list/save-table-relation', [], request, listener);
    }

    /**
     * @param {{page: number, requestObjectType: number, sortRequest: { property: string, asc: boolean }}} request 
     */
    getPaginatedList(request, listener) {
        this.post('/api/paginated-list/get', [], request, listener);
    }

    getItem(objectType, objectId, listener) {
        this.get('/api/list/get-item', [objectType, objectId], listener);
    }

    getItemCreator(objectType, listener) {
        this.get('/api/list/get-item-creator', [objectType], listener);
    }

    saveItem(objectType, update, childrenUpdateRequest, thumbnail, media, listener) {
        const skipCompress = objectType === OBJECT_TYPE_SLIDE;

        this.maybeCompressImage(thumbnail, skipCompress)
            .then(thumbnailImage => {
                const formData = new FormData();
                formData.append('updateJson', JSON.stringify(update));
                if (childrenUpdateRequest !== undefined) {
                    formData.append('childrenUpdateRequestJson', JSON.stringify(childrenUpdateRequest));
                }
                if (thumbnailImage !== undefined) {
                    formData.append('thumbnail', thumbnailImage)
                }
                if (media !== undefined) {
                    formData.append('media', media);
                }
                this.upload('/api/list/save-item', [objectType], formData, listener);
            })
            .catch(() => listener(this.failedApiResponse()))
    }

    deleteItem(objectType, objectId, listener) {
        if (objectType == OBJECT_TYPE_TERMINAL) {
            const terminal = getTerminal();
            if (objectId == terminal.id) {
                listener(this.failedApiResponse("You can not delete your own terminal: " + terminal.terminalId));
                return;
            }
        }
        this.get('/api/list/delete-item', [objectType, objectId], listener);
    }

    getNotes(objectType, objectId, listener) {
        this.get('/api/list/get-notes', [objectType, objectId], listener);
    }

    saveNote(note, attachment, listener) {
        const formData = new FormData();
        formData.append('updateJson', JSON.stringify(note));
        if (attachment !== undefined) {
            formData.append('attachment', attachment)
        }
        this.upload('/api/list/save-note', [], formData, listener);
    }

    getStatementOfAccounts(request, listener) {
        this.post('/api/statement-of-accounts/get', [], request, listener);
    }

    getV2StatementOfAccount(customerId, startDate, endDate, listener) {
        this.get('/api/statement-of-accounts/get', [customerId, startDate, endDate], listener);
    }

    deleteJournalEntry(entryId, listener) {
        this.get('/api/journal-entry/delete-entry', [entryId], listener);
    }

    deleteNote(noteId, listener) {
        this.get('/api/list/delete-note', [noteId], listener);
    }

    barcodeSearch(barcodeValue, listener) {
        this.get('/api/barcode/search', [barcodeValue], listener);
    }

    getProductPreview(productId, listener) {
        this.get('/api/public/product/preview', [productId], listener);
    }

    securityClearanceValidate(clearance, requiredType, listener) {
        this.get('/api/security-clearance/validate', [clearance, requiredType], listener);
    }

    getAllTerminals(listener) {
        this.get('/api/terminal/get-all', [], listener);
    }

    startThirdPartyPos(listener) {
        // this.post('/api/pos/start', [], {
        //     thirdPartyPos: true
        // }, listener);
        this.get('/api/third-party-pos/start', [], listener)
    }

    stopThirdPartyPos(listener) {
        // this.post('/api/pos/stop', [], {
        //     thirdPartyPos: true
        // }, listener);
        this.get('/api/third-party-pos/stop', [], listener)
    }

    startPos(startingAmount, listener) {
        this.post('/api/pos/start', [], {
            terminalId: getTerminal().id,
            startingAmount
        }, listener);
    }

    stopPos(endingAmount, listener) {
        this.post('/api/pos/stop', [], {
            terminalId: getTerminal().id,
            endingAmount
        }, listener);
    }

    setPosOnBreak(securityClearance, onBreak, listener) {
        this.get('/api/pos/session/set-pos-on-break', [securityClearance, getTerminal().id, onBreak], listener);
    }

    changePosCash(request, listener) {
        this.post('/api/pos/session/change-cash', [getTerminal().id], request, listener)
    }

    getPosSession(listener) {
        this.get('/api/pos/get-session', [getTerminal().id], listener);
    }

    getCreateDeliveryOrderInformation(listener) {
        this.get('/api/pos/session/create-delivery-order-information', [getTerminal().id], listener);
    }

    getSalesOrderPosSession(salesOrderId, listener) {
        this.get('/api/pos/get-sales-order-session', [salesOrderId], listener);
    }

    async asyncGetSalesOrderPosSession(salesOrderId) {
        return new Promise((resolve, reject) => this.getSalesOrderPosSession(salesOrderId, response => {
            if (response.status === true) {
                resolve(response.payload)
            } else {
                reject("Could not load order to print")
            }
        }))
    }

    getThirdPartyPosSession(listener) {
        this.get('/api/third-party-pos/get-session', [], listener)
    }

    searchPosCustomer(searchValue, listener) {
        this.get('/api/pos/session/search-customer', [getTerminal().id, searchValue], listener);
    }

    setPosCustomer(id, listener) {
        this.get('/api/pos/session/set-customer', [getTerminal().id, id], listener);
    }

    setPosCustomerForSalesOrder(salesOrderId, customerId, listener) {
        this.get('/api/pos/session/set-customer-for-sales-order', [salesOrderId, customerId], listener);
    }

    getSalesCart(cartId, listener) {
        this.get('/api/sales/get-cart', [cartId], listener)
    }

    getProductCosts(productIds, listener) {
        this.post('/api/cost/get-costs', [], productIds, listener);
    }



    getSaleWorksList(mode, page, listener) {
        this.get('/api/sale-work/get', [mode, page], listener)
    }

    getSaleWorksListEndpoint(listener) {
        this.get('/api/sale-work/endpoint', [], listener);
    }

    getSaleWorksListStat(mode, listener) {
        this.get('/api/sale-work/stats', [mode], listener)
    }



    getSalesCartWork(workCartId, listener) {
        this.get('/api/sales/get-cart-work', [workCartId], listener)
    }

    createSalesCartWork(salesCartId, customerId, venueId, label, listener) {
        this.post('/api/sales/create-cart-work', [], { salesCartId, customerId, venueId, label }, listener)
    }

    createSalesCart(listener) {
        this.get('/api/sales/create-cart', [], listener)
    }

    getSalesCarts(listener) {
        this.get('/api/sales/get-carts', [], listener)
    }

    getCustomerAddresses(customerId, listener) {
        this.get('/api/customer/address', [customerId], listener);
    }

    saveCustomerAddress(address, listener) {
        this.post('/api/customer/address/save', [], address, listener);
    }

    deleteCustomerAddress(addressId, listener) {
        this.get('/api/customer/address/delete', [addressId], listener)
    }

    deleteOngoingWork(workId, listener) {
        this.get('/api/sales/delete-ongoing-work', [workId], listener)
    }

    revokeQuotation(workId, listener) {
        this.get('/api/sales/revoke-quotation', [workId], listener)
    }

    listDetailSaveData(reportName, data, listener) {
        this.post("/api/reporting/save-data", [reportName], data, listener);
    }

    listDetailGetData(reportName, id, listener) {
        this.get("/api/reporting/get-data", [reportName, id], listener);
    }

    listDetailDeleteData(reportName, id, listener) {
        this.get("/api/reporting/delete-data", [reportName, id], listener);
    }

    revokeSalesOrder(salesOrderId, rejected, reason, listener) {
        this.post('/api/sales-order/revoke-order', [salesOrderId, rejected], { content: reason }, listener);
        // listener({
        //     status: false,
        //     message: "You are not authorized",
        //     payload: null
        // })
    }

    createSalesOrderStockFlow(salesOrderId, courierId, paymentMethods, listener) {
        this.postWithHeaders('/api/sales-order/create-sales-stock-flow', [], {
            salesOrderId, paymentMethods,
            terminalId: getTerminal().id,
        }, listener, {
            'rbt-courier-subsidiary-id': courierId
        })
    }

    startRestOrder(salesOrderId, listener) {
        this.get("/api/sales-order/rest/start-order", [salesOrderId], listener);
    }

    serveOrder(salesOrderId, listener) {
        this.get("/api/sales-order/rest/serve-order", [salesOrderId], listener);
    }

    servedOrder(salesOrderId, listener) {
        this.get("/api/sales-order/rest/served-order", [salesOrderId], listener);
    }

    stopServingOrder(salesOrderId, listener) {
        this.get("/api/sales-order/rest/stop-serving-order", [salesOrderId], listener);
    }

    packOrder(salesOrderId, listener) {
        this.get("/api/sales-order/rest/pack-order", [salesOrderId], listener);
    }

    packedOrder(salesOrderId, listener) {
        this.get("/api/sales-order/rest/packed-order", [salesOrderId], listener);
    }

    stopPackingOrder(salesOrderId, listener) {
        this.get("/api/sales-order/rest/stop-packing-order", [salesOrderId], listener);
    }

    sendOrderForDelivery(salesOrderId, driverId, listener) {
        this.get("/api/sales-order/rest/send-order-for-delivery", [salesOrderId, driverId], listener);
    }

    assignSalesOrderDelivery(salesOrderId, driverId, listener) {
        this.get('/api/sales-order/assign-delivery', [salesOrderId, driverId], listener);
    }

    confirmSalesOrderOrder(salesOrderId, targetDeliveryDate, targetRentalPickupDate, shippingLocation, listener) {
        this.post('/api/sales-order/confirm-order', [], {
            salesOrderId, targetDeliveryDate, targetRentalPickupDate, shippingLocation
        }, listener)
    }

    toggleOrderDeliveryFee(orderId, enable, reason, listener) {
        this.post('/api/rest-order/toggle-delivery-fee', [orderId, enable], { content: reason }, listener)
    }

    asyncToggleOrderDeliveryFee(orderId, enable, reason) {
        return new Promise((resolve, reject) => this.toggleOrderDeliveryFee(orderId, enable, reason, response => {
            if (response.status === true) {
                resolve(response.payload)
            } else {
                reject({ roboErpApiErr: true, msg: response.message })
            }
        }))
    }

    setSalesOrderNote(salesOrderId, note, listener) {
        this.post('/api/sales-order/set-order-note', [salesOrderId], { content: note }, listener);
    }

    setConfirmOrderData(req, listener) {
        this.post('/api/sales-order/set-confirm-order-data', [], req, listener);
    }

    confirmSalesOrderOrderWithObjReq(req, listener) {
        this.post('/api/sales-order/confirm-order', [], req, listener)
    }

    salesOrderRevokeDriverAssignation(salesOrderId, listener) {
        this.get('/api/sales-order/revoke-driver-assignation', [salesOrderId], listener);
    }

    salesOrderExternalDelivered(salesOrderId, listener) {
        this.get('/api/sales-order/external-delivered', [salesOrderId], listener);
    }

    salesOrderSetDriver(salesOrderId, driverId, listener) {
        this.get('/api/sales-order/set-driver', [salesOrderId, driverId], listener);
    }

    getSalesOrderAvailableDrivers(listener) {
        this.get('/api/sales-order/get-available-drivers', [], listener);
    }

    getSalesOrderAvailableCouriers(listener) {
        this.get('/api/sales-order/get-available-couriers', [], listener);
    }

    createSalesOrderRentalStockFlow(salesOrderId, destType, destId, listener) {
        this.get('/api/sales-order/create-rental-stock-flow', [salesOrderId, destType, destId], listener)
    }

    forceStopPosSession(posSessionId, listener) {
        this.get('/api/monitoring/force-stop-session', [posSessionId], listener)
    }

    createQuotation(request, listener) {
        this.post('/api/sales/create-quotation', [], request, listener)
    }

    createSalesOrder(request, listener) {
        this.post('/api/sales/create-sales-order', [], request, listener)
    }

    createCartSale(request, listener) {
        this.post('/api/sales/create-sale', [], request, listener)
    }

    replaceCartItemItemId(cartItemId, newItemId, listener) {
        this.get('/api/pos/session/replace-cart-item-item-id', [getTerminal().id, cartItemId, newItemId], listener);
    }

    setCartItemCustomPrice(cartItemId, customPrice, clearanceCode, listener) {
        this.get('/api/pos/session/set-cart-item-custom-price', [getTerminal().id, cartItemId, customPrice, clearanceCode], listener);
    }

    setCartItemCustomPrice2(parentId, cartItemId, customPrice, clearanceCode, listener) {
        this.get('/api/pos/session/set-cart-item-custom-price', [parentId, cartItemId, customPrice, clearanceCode], listener);
    }

    setPosSalesperson(salespersonId, listener) {
        this.get('/api/pos/session/set-salesperson', [getTerminal().id, salespersonId], listener);
    }

    setPosSalesperson2(parentId, salespersonId, listener) {
        this.get('/api/pos/session/set-salesperson', [parentId, salespersonId], listener);
    }

    setPosNoTax(parentId, noTax, listener) {
        this.get('/api/pos/session/set-no-tax', [parentId, noTax], listener)
    }

    setPosInventoryReadyDate(parentId, readyDate, listener) {
        this.get('/api/pos/session/set-inventory-ready-date', [parentId, readyDate], listener)
    }

    setPosMemo(parentId, memo, listener) {
        this.post("/api/pos/session/set-memo", [parentId], { content: memo }, listener)
    }

    setPosAwbNo(parentId, awbNo, listener) {
        this.post("/api/pos/session/set-awb-no", [parentId], { content: awbNo }, listener)
    }

    setPosCourier(parentId, courierId, listener) {
        this.get('/api/pos/session/set-courier-id', [parentId, courierId], listener)
    }

    setPosInventoryStatus(parentId, inventoryStatus, listener) {
        this.post("/api/pos/session/set-inventory-status", [parentId], { content: inventoryStatus }, listener)
    }

    setPosInventoryAllocate(parentId, value, listener) {
        this.get('/api/pos/session/set-inventory-allocate', [parentId, value], listener)
    }

    setPosInventoryNote(parentId, inventoryNote, listener) {
        this.post("/api/pos/session/set-inventory-note", [parentId], { content: inventoryNote }, listener)
    }

    setPosWaiter(waiterId, listener) {
        this.get('/api/pos/session/set-waiter', [getTerminal().id, waiterId], listener);
    }

    setPosTable(tableId, listener) {
        this.get('/api/pos/session/set-table', [getTerminal().id, tableId], listener);
    }

    setPosNoOfPeopleDineIn(noOfPeopleDineIn, listener) {
        this.get('/api/pos/session/set-no-of-people-dine-in', [getTerminal().id, noOfPeopleDineIn], listener);
    }

    setCartDataWaiterCalled(value, listener) {
        this.get('/api/pos/session/setCartDataWaiterCalled', [getTerminal().id, value], listener);
    }

    setCartDataOrderSubmitted(value, listener) {
        this.get('/api/pos/session/setCartDataOrderSubmitted', [getTerminal().id, value], listener);
    }

    setCartDataModifyOrderSubmitted(value, listener) {
        this.get('/api/pos/session/setCartDataModifyOrderSubmitted', [getTerminal().id, value], listener);
    }

    setPosRestOrderType(restOrderType, listener) {
        this.post('/api/pos/session/set-rest-order-type', [getTerminal().id], { content: restOrderType }, listener);
    }

    clearPosCustomer(listener) {
        this.get('/api/pos/session/clear-customer', [getTerminal().id], listener);
    }

    clearPosCustomerForSalesOrder(salesOrderId, listener) {
        this.get('/api/pos/session/clear-customer-for-sales-order', [salesOrderId], listener);
    }

    scanPosBarcode(barcode, listener) {
        this.post('/api/pos/scan-barcode', [getTerminal().id], {
            content: barcode
        }, listener);
    }

    savePosPrint(req, listener) {
        this.post("/api/saved-pos-print/save", [getTerminal().id], req, listener);
    }

    getSavedPosPrints(search, page, listener) {
        this.post("/api/saved-pos-print/get-list", [getTerminal().id, page], { content: search }, listener)
    }

    searchCustomer(search, page, listener) {
        this.post('/api/customer/search', [page], { content: search }, listener);
    }

    searchProduct2(search, page, listener) {
        this.post('/api/search/product', [page], { content: search }, listener);
    }

    searchStockReqItems(search, page, listener) {
        this.post('/api/search/stock-req-items', [page], { content: search }, listener);
    }

    searchCargoItems(search, page, listener) {
        this.post('/api/search/cargo-items', [page], { content: search }, listener);
    }

    getMiniCustomer(customerId, listener) {
        this.get('/api/customer/get-mini-customer', [customerId], listener);
    }

    bulkIssueLoyaltyCard(request, listener) {
        this.post('/api/loyalty-points/bulk-issue-cards', [], request, listener);
    }

    searchStockReqItemsByBarcode(barcode, listener) {
        this.get('/api/search/stock-req-items/by-barcode', [barcode], listener);
    }

    searchShipment(search, page, listener) {
        this.post('/api/search/shipment', [page], { content: search }, listener);
    }

    searchSellable(search, page, listener) {
        this.post('/api/search/sellable', [page], { content: search }, listener);
    }

    searchFnbEventSellable(search, page, listener) {
        this.post('/api/search/fnb-event-sellable', [page], { content: search }, listener);
    }

    v1PoRevoke(id, reason, listener) {
        this.post('/api/purchase-orders/revoke', [id], { content: reason }, listener);
    }

    v1PoFinalize(id, listener) {
        this.get('/api/purchase-orders/finalize', [id], listener);
    }

    getProductCurrentQty(locationId, itemId, listener) {
        this.get('/api/inv/adjust/get-current-qty', [locationId, itemId], listener);
    }

    invAdjustmentFinalize(id, listener) {
        this.get('/api/inv/adjust/finalize', [id], listener);
    }

    makeInvRequestPending(invRequestId, listener) {
        this.get('/api/inv-requests/make-pending', [invRequestId], listener);
    }

    makeInvRequestCancelled(invRequestId, note, listener) {
        this.post('/api/inv-requests/make-cancelled', [invRequestId], { content: note }, listener);
    }

    makeInvRequestSent(invRequestId, listener) {
        this.get('/api/inv-requests/make-sent', [invRequestId], listener);
    }

    revokeInvRequest(invRequestId, note, listener) {
        this.post('/api/inv-requests/revoke', [invRequestId], { content: note }, listener);
    }

    makeInvRequestReceived(invRequestId, listener) {
        this.get('/api/inv-requests/make-received', [invRequestId], listener);
    }

    makeInvRequestApproved(invRequestId, listener) {
        this.get('/api/inv-requests/make-approved', [invRequestId], listener);
    }

    makeInvRequestRejected(invRequestId, listener) {
        this.get('/api/inv-requests/make-rejected', [invRequestId], listener);
    }

    revertInvRequestItem(invRequestId, lineId, listener) {
        this.get('/api/inv-requests/revert-item', [invRequestId, lineId], listener);
    }

    rejectInvRequestItem(requestId, lineId, listener) {
        this.get('/api/inv-requests/reject-item', [requestId, lineId], listener);
    }

    approveAllInvRequestItems(requestId, listener) {
        this.get('/api/inv-requests/approve-all-items', [requestId], listener);
    }

    updateInvRequestRespondingNote(requestId, note, listener) {
        this.post('/api/inv-requests/update-responding-note', [requestId], { content: note }, listener);
    }

    updateInvRequestReceivingNote(requestId, note, listener) {
        this.post('/api/inv-requests/update-receiving-note', [requestId], { content: note }, listener);
    }

    approveInvRequestItem(requestId, lineId, listener) {
        this.get('/api/inv-requests/approve-item', [requestId, lineId], listener);
    }

    partialInvRequestItem(requestId, lineId, qty, listener) {
        this.get('/api/inv-requests/partial-item', [requestId, lineId, qty], listener);
    }

    additionalInvRequestItem(requestId, lineId, qty, listener) {
        this.get('/api/inv-requests/additional-item', [requestId, lineId, qty], listener);
    }

    replaceInvRequestItem(requestId, lineId, replaceItemId, replaceItemType, qty, uomId, listener) {
        this.get('/api/inv-requests/replace-item', [requestId, lineId, replaceItemId, replaceItemType, qty, uomId], listener);
    }

    getInvRequest(id, listener) {
        this.get('/api/inv-requests/get', [id], listener);
    }

    addStockFlowToInvRequest(invId, sfId, listener) {
        this.get('/api/inv-requests/add-stock-flow', [invId, sfId], listener);
    }

    removeStockFlowFromInvRequest(invId, sfId, listener) {
        this.get('/api/inv-requests/remove-stock-flow', [invId, sfId], listener);
    }

    updateBillShipments(billId, shipments, listener) {
        this.post('/api/bill/update-bill-shipments', [billId], shipments, listener)
    }

    searchIngredients(search, page, listener) {
        this.post('/api/search/ingredient', [page], { content: search }, listener)
    }

    searchInvoice(search, page, listener) {
        this.post('/api/search/invoice', [page], { content: search }, listener)
    }

    searchInvReqAssignSf(search, page, listener) {
        this.post('/api/search/inv-req-assign-sf', [page], { content: search }, listener)
    }

    searchInvReqList(search, page, listener) {
        this.post('/api/search/inv-req-list', [page], { content: search }, listener)
    }

    searchProducibleBundles(search, page, listener) {
        this.post('/api/search/producible-bundles', [page], { content: search }, listener)
    }

    searchFoodItemRecipeItems(search, page, listener) {
        this.post('/api/search/food-item-recipe-items', [page], { content: search }, listener);
    }

    packingListLoadFromStockFlow(id, listener) {
        this.get('/api/packing-list/load-from-stock-flow', [id], listener)
    }

    checkoutPos(request, listener) {
        this.post('/api/pos/session/checkout', [getTerminal().id], request, listener);
    }

    createRestCart(listener) {
        this.get('/api/pos/session/create-rest-cart', [getTerminal().id], listener)
    }

    posCreateSalesOrder(request, listener) {
        this.post('/api/pos/session/create-sales-order', [getTerminal().id], request, listener);
    }

    payOrderBeforeInvoice(orderId, methods, listener) {
        this.post("/api/rest-order/pay-order-before-invoice", [orderId], methods, listener);
    }

    async asyncPayOrderBeforeInvoice(orderId, methods) {
        return new Promise((resolve, reject) => this.payOrderBeforeInvoice(orderId, methods, response => {
            if (response.status === true) {
                resolve(response.payload)
            } else {
                reject({ roboErpApiErr: true, msg: response.message })
            }
        }))
    }

    sendPosReceiptSms(invoiceId, number, receiptHtml, listener) {
        this.post("/api/saved-pos-print/send-receipt-sms", [], { invoiceId, number, receiptHtml }, listener)
    }

    createPropertyDeal(req, listener) {
        this.post('/api/property-deal/create', [], req, listener);
    }

    updatePropertyDeal(req, listener) {
        this.post('/api/property-deal/update', [], req, listener);
    }

    confirmPropertyDeal(req, listener) {
        this.post('/api/property-deal/confirm', [], req, listener);
    }

    getPropertyDealEndpoints(leadId, dealId, listener) {
        this.get('/api/property-deal/get-deal-endpoints', [leadId, dealId], listener);
    }

    getAdvancedNotes(type, id, archived, listener) {
        this.get('/api/pro-design/get-nodes', [type, id, archived], listener)
    }

    reorderAdvancedNotes(type, id, order, listener) {
        this.post('/api/pro-design/reorder-nodes', [type, id], order, listener)
    }

    guardianGetMyStudents(listener) {
        this.get('/api/guardian/get-my-students', [], listener);
    }

    doIHaveAnyUnpaidInvoices(listener) {
        this.get('/api/service-sale/do-i-have-any-unpaid-invoices', [], listener)
    }

    saveAdvancedNote(note, attachment, listener) {
        const formData = new FormData();
        formData.append('updateJson', JSON.stringify(note));
        if (attachment !== undefined) {
            formData.append('attachment', attachment)
        }
        this.upload('/api/pro-design/save-node', [], formData, listener);
    }

    archiveAdvancedNote(id, rootParentId, rootParentType, listener) {
        this.get('/api/pro-design/archive-node', [id, rootParentId, rootParentType], listener);
    }

    restoreAdvancedNote(id, listener) {
        this.get('/api/pro-design/restore-node', [id], listener);
    }

    deletePropertyDeal(dealId, listener) {
        this.get('/api/property-deal/delete-deal', [dealId], listener);
    }

    getRawLocationEvents(parentId, listener) {
        this.get('/api/location-track/get-raw-events', [parentId], listener)
    }

    updateSalesCart(update, listener) {
        this.post('/api/sales/update-cart', [], update, listener)
    }

    setSalesCartVisibility(cartId, visibility, listener) {
        this.get('/api/sales/set-cart-visibility', [cartId, visibility], listener)
    }

    setPosCartItemNote(cartItemId, note, listener) {
        this.post('/api/pos/session/set-cart-item-note', [getTerminal().id, cartItemId], { content: note }, listener);
    }

    updateCartItem(request, listener) {
        this.post('/api/pos/session/update-cart-item', [getTerminal().id], request, listener);
    }

    updateCartItemRecipeOptions(parentId, request, listener) {
        this.post('/api/pos/session/update-cart-item-recipe-options', [parentId], request, listener);
    }

    updateCartItemRecipeOptionsForSalesOrder(parentId, request, listener) {
        this.post('/api/pos/session/update-cart-item-recipe-options-for-sales-order', [parentId], request, listener);
    }



    updateCartItemGender(cartItemId, gender, listener) {
        this.get('/api/pos/session/set-cart-item-gender', [getTerminal().id, cartItemId, gender], listener);
    }
    updateCartItemGenderForSalesOrder(orderId, cartItemId, gender, listener) {
        this.get('/api/pos/session/set-cart-item-gender-for-sales-order', [orderId, cartItemId, gender], listener);
    }



    updateCartItem2(request, parentId, listener) {
        this.post('/api/pos/session/update-cart-item', [parentId], request, listener);
    }

    updateSalesOrderCartItem(salesOrderId, request, listener) {
        this.post('/api/pos/session/update-sales-order-cart-item', [salesOrderId], request, listener);
    }

    checkoutThirdPartyPos(listener) {
        this.get('/api/third-party-pos/session/checkout', [], listener)
    }

    updateThirdPartyPosCartItem(request, listener) {
        this.post('/api/third-party-pos/session/update-cart-item', [], request, listener);
    }

    setThirdPartyPosComplimentary(cartItemId, complimentary, listener) {
        this.get('/api/third-party-pos/session/set-complimentary', [cartItemId, complimentary], listener);
    }

    getProductDivisionOptions(productGroupId, listener) {
        this.get('/api/product-group-division/get-options', [productGroupId], listener);
    }

    getAllProductAttributes(productId, listener) {
        this.get('/api/product-attributes/get-all', [productId], listener);
    }

    updateProductAttributeValues(productId, values, listener) {
        this.post('/api/product-attributes/update', [productId], values, listener);
    }

    createQuotationFromPos(label, expirationDate, listener) {
        this.post('/api/pos/session/create-quotation', [getTerminal().id, expirationDate], { content: label }, listener);
    }

    getHeldCarts(listener) {
        this.get('/api/pos/get-held-carts', [getTerminal().id], listener);
    }

    setCart(cartId, listener) {
        this.get('/api/pos/session/set-cart', [getTerminal().id, cartId], listener);
    }

    newCart(listener) {
        this.get('/api/pos/session/new-cart', [getTerminal().id], listener);
    }

    deleteCart(cartId, listener) {
        this.get('/api/pos/session/delete-cart', [getTerminal().id, cartId], listener);
    }

    clearCart(listener) {
        this.get('/api/pos/session/clear-cart', [getTerminal().id], listener);
    }

    clearCart2(terminalId, listener) {
        this.get('/api/pos/session/clear-cart', [terminalId], listener);
    }

    clearThirdPartyPosCart(listener) {
        this.get('/api/third-party-pos/session/clear-cart', [], listener)
    }

    setOpeningBalance(ledgerId, amount, listener) {
        this.get('/api/accounting/set-opening-balance', [ledgerId, amount], listener)
    }

    setSubsidiaryOpeningBalance(subsidiaryId, subsidiaryType, amount, listener) {
        this.get('/api/accounting/set-subsidiary-opening-balance', [subsidiaryId, subsidiaryType, amount], listener)
    }

    addFreeFormDiscount(amount, clearanceCode, percMode, forCartItemId, listener) {
        this.post('/api/pos/session/add-free-form-discount', [getTerminal().id], { amount, clearanceCode, percMode, forCartItemId, updatePos: false }, listener)
    }

    addFreeFormDiscount2(parentId, amount, clearanceCode, percMode, forCartItemId, listener) {
        this.post('/api/pos/session/add-free-form-discount', [parentId], { amount, clearanceCode, percMode, forCartItemId, updatePos: false, skipLegacyClearanceCode: false }, listener)
    }

    addFreeFormDiscountFromAdmin(amount, clearanceCode, percMode, forCartItemId, terminalUserId, terminalId, listener) {
        this.post('/api/pos/session/add-free-form-discount', [terminalId], { amount, clearanceCode, percMode, forCartItemId, updatePos: true, skipLegacyClearanceCode: false, terminalUserId }, listener)
    }

    addFreeFormDiscountForSalesOrder(amount, clearanceCode, percMode, salesOrderId, listener) {
        this.post('/api/pos/session/add-free-form-discount-for-sales-order', [salesOrderId], { amount, clearanceCode, percMode, updatePos: true }, listener)
    }

    getHtmlEditorContent(targetType, targetId, listener) {
        this.get('/api/html-editor/get-content', [targetType, targetId], listener);
    }

    saveHtmlEditorContent(targetType, targetId, req, listener) {
        this.post('/api/html-editor/save-content', [targetType, targetId], req, listener)
    }

    removeDiscount(terminalId, discountId, listener) {
        this.get('/api/pos/session/remove-discount', [terminalId, discountId], listener)
    }

    removeDiscountForSalesOrder(salesOrderId, discountId, listener) {
        this.get('/api/pos/session/remove-discount-for-sales-order', [salesOrderId, discountId], listener)
    }

    removeDiscountFromAdmin(userId, terminalId, discountId, listener) {
        this.get('/api/pos/session/remove-discount-from-admin', [userId, terminalId, discountId], listener)
    }

    setApplyCoupon(couponCode, apply, listener) {
        this.get('/api/pos/session/set-apply-coupon', [getTerminal().id, couponCode, apply], listener);
    }

    setApplyCouponForSalesOrder(salesOrderId, couponCode, apply, listener) {
        this.get('/api/pos/session/set-apply-coupon-for-sales-order', [salesOrderId, couponCode, apply], listener);
    }

    setApplyLoyaltyPoints(apply, listener) {
        this.get('/api/pos/session/set-apply-loyalty-points', [getTerminal().id, apply], listener);
    }

    getFoodStockEndpoint(listener) {
        this.get('/api/food-stock/endpoint', [], listener)
    }

    getFoodStockValues(storeId, listener) {
        this.get('/api/food-stock/values', [storeId], listener);
    }

    saveFoodStockValues(storeId, update, listener) {
        this.post('/api/food-stock/save', [storeId], update, listener)
    }

    createStockRequest(reqItems, listener) {
        this.post("/api/stock-request/create-stock-request", [], reqItems, listener)
    }

    revokeMyStockRequest(id, listener) {
        this.get("/api/stock-request/revoke-my-request", [id], listener)
    }

    rejectStockRequest(id, listener) {
        this.get("/api/stock-request/reject-request", [id], listener)
    }

    approveStockRequest(id, sourceType, sourceId, listener) {
        this.get("/api/stock-request/approve-request", [id, sourceType, sourceId], listener)
    }

    getMyStockRequests(status, listener) {
        this.get("/api/stock-request/get-my-requests", [status], listener)
    }

    getMyStockRequest(id, listener) {
        this.get("/api/stock-request/get-my-request", [id], listener)
    }

    revokeInvoiceDocument(id, listener) {
        this.get('/api/invoice-document/revoke', [id], listener);
    }

    revokePurchase(id, listener) {
        this.get('/api/purchase/revoke', [id], listener);
    }

    getPurchase(id, listener) {
        this.get('/api/purchase/get-purchase', [id], listener);
    }

    createPurchase(req, listener) {
        this.post('/api/purchase/create-purchase', [], req, listener)
    }

    getPurchases(payeeId, status, listener) {
        this.get('/api/purchase/get-purchases', [payeeId, status], listener);
    }

    //----sales

    revokeSale(id, listener) {
        this.get('/api/sale/revoke', [id], listener);
    }

    getSale(id, listener) {
        this.get('/api/sale/get-sale', [id], listener);
    }

    createSale(req, listener) {
        this.post('/api/sale/create-sale', [], req, listener)
    }

    getSales(payerId, status, listener) {
        this.get('/api/sale/get-sales', [payerId, status], listener);
    }

    //----

    //----- sale service ---

    revokeServiceSale(id, listener) {
        this.get('/api/service-sale/revoke', [id], listener);
    }

    getServiceSale(id, listener) {
        this.get('/api/service-sale/get-service-sale', [id], listener);
    }

    createServiceSale(req, listener) {
        this.post('/api/service-sale/create-service-sale', [], req, listener)
    }

    getServiceSales(payerId, status, showTicketSalesOnly, listener) {
        this.get('/api/service-sale/get-service-sales', [payerId, status, showTicketSalesOnly], listener);
    }

    getCloseBooksDetail(listener) {
        this.get('/api/close-books/get-detail', [], listener);
    }

    setCloseBooksDetail(detail, listener) {
        this.post('/api/close-books/set-detail', [], detail, listener);
    }

    getInvoiceDocument(id, listener) {
        this.get('/api/invoice-document/get-invoice-document', [id], listener);
    }

    getPayout(id, listener) {
        this.get('/api/payout/get-payout', [id], listener);
    }

    generateInvoiceDocument(req, listener) {
        this.post('/api/invoice-document/create-venue-invoice-document', [], req, listener);
    }

    getInvoiceDocumentEndpoints(listener) {
        this.get('/api/invoice-document/get-invoice-document-endpoints', [], listener);
    }

    getInvoiceDocuments(payerId, status, listener) {
        this.get('/api/invoice-document/get-invoice-documents', [payerId, status], listener);
    }

    revokeBill(id, listener) {
        this.get('/api/bill/revoke', [id], listener);
    }

    getBill(id, listener) {
        this.get('/api/bill/get-bill', [id], listener);
    }

    createBill(req, listener) {
        this.post('/api/bill/create-bill', [], req, listener)
    }

    updateBill(req, listener) {
        this.post('/api/bill/update-bill', [], req, listener)
    }

    getBills(payeeId, status, listener) {
        this.get('/api/bill/get-bills', [payeeId, status], listener);
    }

    getPropertyDeals(status, listener) {
        this.get("/api/property-deal/get-deals", [status], listener)
    }

    getPropertyDeal(id, listener) {
        this.get('/api/property-deal/get-deal', [id], listener);
    }

    revokeCommission(id, listener) {
        this.get('/api/commission/revoke', [id], listener);
    }

    getCommission(id, listener) {
        this.get('/api/commission/get-commission', [id], listener);
    }

    createCommission(req, listener) {
        this.post('/api/commission/create-commission', [], req, listener)
    }

    getCommissions(payeeId, status, listener) {
        this.get('/api/commission/get-commissions', [payeeId, status], listener);
    }

    createPayout(req, listener) {
        this.post('/api/payout/create-payout', [], req, listener)
    }

    setShipmentStamp(id, stamp, listener) {
        this.get('/api/shipment/set-stamp', [id, stamp], listener);
    }

    setShipmentLocked(id, locked, listener) {
        this.get('/api/shipment/set-locked', [id, locked], listener);
    }

    setShipmentInvoiceDate(id, date, listener) {
        this.get('/api/shipment/set-invoice-date', [id, date], listener);
    }

    getPayouts(payeeId, status, listener) {
        this.get('/api/payout/get-payouts', [payeeId, status], listener);
    }

    getAllStockRequests(status, listener) {
        this.get("/api/stock-request/get-all-requests", [status], listener)
    }

    getStockRequest(id, listener) {
        this.get("/api/stock-request/get-request", [id], listener)
    }

    getServicesAndPackages(listener) {
        this.get("/api/service/get-services-and-packages", [], listener);
    }

    getAllCategories(listener) {
        this.get('/api/product/get-all-categories', [], listener);
    }

    getSystemValue(key, listener) {
        this.get('/api/system-value/get-value', [key], listener);
    }

    setSystemValue(key, value, listener) {
        this.post('/api/system-value/set-value', [key], {
            content: value
        }, listener)
    }

    getProductMeasurementType(productId, loadPackageUnits, listener) {
        this.get('/api/measurement-type/get-product-measurement-type', [productId, loadPackageUnits], listener)
    }

    getOutstandingReport(listener) {
        this.get("/api/outstanding-report/get", [], listener)
    }

    getStudentOutstandingReport(listener) {
        this.get("/api/student-outstanding-report/get", [], listener)
    }

    getAllStudentPresences(activityId, listener) {
        this.get('/api/student-presence/get-all-presences', [activityId], listener)
    }

    getAllLearningComponents(listener) {
        this.get('/api/learning/lecture/get-all-components', [], listener)
    }

    getLectureQuizQuestions(quizId, listener) {
        this.get('/api/learning/quiz/get-questions', [quizId], listener);
    }

    saveLectureQuizQuestions(quizId, questions, listener) {
        this.post('/api/learning/quiz/save-quiz-questions', [quizId], questions, listener)
    }

    getAllCrv(page, request, listener) {
        this.post('/api/crv/get-all-crv', [], { page, ...request }, listener)
    }

    getCrv(crvId, listener) {
        this.get('/api/crv/get-crv', [crvId], listener)
    }

    createCrv(request, listener) {
        this.post('/api/crv/create-crv', [], request, listener)
    }

    getCrvReport(listener) {
        this.get('/api/crv/get-crv-report', [], listener);
    }

    getCRVEndpointsList(listener) {
        this.get('/api/crv/get-endpoints-list', [], listener);
    }

    getTransactionsRequiringCashReturn(cashBorrowerId, listener) {
        this.get('/api/crv/get-transactions-requiring-cash-return', [cashBorrowerId], listener);
    }

    getInvoiceForV2Sale(v2SaleId, listener) {
        this.get('/api/invoice/get-invoice-for-v2sale', [v2SaleId], listener)
    }

    getSellables(listener) {
        this.get('/api/item-list/get-sellables', [], listener)
    }

    createV2Sale(request, listener) {
        this.post('/api/v2/sales/create', [], request, listener);
    }

    getV2Sale(id, listener) {
        this.get('/api/v2/sales/get', [id], listener);
    }

    getV2SalesEndpoint(listener) {
        this.get("/api/v2/sales/endpoint", [], listener);
    }

    getV2BillsEndpoint(listener) {
        this.get('/api/v2/bills/endpoint', [], listener);
    }

    getV2InvoiceableSalesOrderItems(request, listener) {
        this.post('/api/v2/sales/invoiceable-sales-order-items', [], request, listener);
    }

    getProductsForPos(categoryId, page, listener) {
        this.get('/api/product/get-products-for-pos', [categoryId, page], listener);
    }

    getBundlesForPos(page, listener) {
        this.get('/api/product/get-bundles-for-pos', [page], listener)
    }

    searchProductsForPos(search, page, listener) {
        this.post('/api/product/search-products-for-pos', [page], {
            content: search
        }, listener)
    }

    getThirdPartyPosProducts(req, listener) {
        this.post('/api/third-party-post/get-products', [], req, listener);
    }

    getPosProducts(req, listener) {
        this.post('/api/product/get-pos-products', [], req, listener);
    }

    getProductsForPos2(categoryId, page, storeId, listener) {
        this.get('/api/product/get-products-for-pos', [categoryId, page, storeId], listener);
    }

    getBundlesForPos2(page, storeId, listener) {
        this.get('/api/product/get-bundles-for-pos', [page, storeId], listener)
    }

    searchProductsForPos2(search, page, storeId, listener) {
        this.post('/api/product/search-products-for-pos', [page, storeId], {
            content: search
        }, listener)
    }

    getAllProducts(listener) {
        this.get('/api/product/get-all-products', [getTerminal().storeId], listener);
    }

    updateNegativeStockSetting(update, listener) {
        this.post('/api/settings/update-negative-stock-setting', [], update, listener)
    }

    getNegativeStockSetting(listener) {
        this.get('/api/settings/get-negative-stock-setting', [], listener)
    }

    isSettingEnabled(key, listener) {
        this.get('/api/settings/is-setting-enabled', [key], listener)
    }

    getSettingsValues(listener) {
        this.get('/api/settings/set-settings-values', [], listener);
    }

    getShipment(shipmentId, listener) {
        this.get('/api/shipment/get', [shipmentId], listener);
    }

    getPublicShipment(shipmentId, listener) {
        this.get('/api/public/shipment/get-shipment', [shipmentId], listener);
    }

    asyncGetShipment(shipmentId) {
        return new Promise((resolve, reject) => this.getShipment(shipmentId, response => {
            if (response.status === true) {
                resolve(response.payload)
            } else {
                reject(response.message)
            }
        }))
    }

    getShipmentAndLock(shipmentId, listener) {
        this.get('/api/shipment/get-and-lock', [shipmentId], listener);
    }

    getShipmentFromPackage(id, listener) {
        this.get('/api/shipment/get-from-package', [id], listener);
    }

    getShipmentFromItem(id, listener) {
        this.get('/api/shipment/get-from-item', [id], listener);
    }

    getPublicShipmentFromPackage(id, listener) {
        this.get('/api/public/shipment/shipment-from-package', [id], listener);
    }

    getPublicShipmentFromItem(id, listener) {
        this.get('/api/public/shipment/shipment-from-item', [id], listener);
    }

    createShipment(request, listener) {
        this.post('/api/shipment/create', [], request, listener);
    }

    updateShipment(request, listener) {
        this.post('/api/shipment/update', [], request, listener);
    }

    getShipmentMaster(masterId, listener) {
        this.get('/api/shipment-master/get', [masterId], listener);
    }

    getPublicShipmentMaster(masterId, listener) {
        this.get('/api/public/shipment/get-shipment-master', [masterId], listener);
    }

    createShipmentMaster(request, listener) {
        this.post('/api/shipment-master/create', [], request, listener);
    }

    updateShipmentMaster(request, listener) {
        this.post('/api/shipment-master/update', [], request, listener);
    }

    getShipmentConvertedInvoice(shipmentId, currencyId, listener) {
        this.get('/api/shipment/get-converted-invoice', [shipmentId, currencyId], listener);
    }

    asyncGetShipmentConvertedInvoice(shipmentId, currencyId) {
        return new Promise((resolve, reject) => this.getShipmentConvertedInvoice(shipmentId, currencyId, response => {
            if (response.status === true) {
                resolve(response.payload)
            } else {
                reject({ roboErpApiErr: true, msg: response.message })
            }
        }))
    }

    getProductStock(locationId, productId, listener) {
        this.get('/api/stock/get-product-stock', [locationId, productId], listener)
    }

    asyncGetProductStock(locationId, productId) {
        return new Promise((resolve, reject) => this.getProductStock(locationId, productId, response => {
            if (response.status === true) {
                resolve(response.payload)
            } else {
                reject({ roboErpApiErr: true, msg: response.message })
            }
        }))
    }

    // getPortShipment(shipmentId, listener) {
    //     this.get('/api/port-shipment/get', [shipmentId], listener);
    // }

    getPortShipments(listener) {
        this.get('/api/port-shipment/list', [], listener)
    }

    setSettingEnable(settingKey, enable, listener) {
        this.get('/api/settings/set-setting-value', [settingKey, enable], listener)
    }

    searchProducts(searchValue, page, cancelToken, listener) {
        this.post('/api/product/search-products', [page], {
            content: searchValue
        }, listener, cancelToken);
    }

    searchProductsAndRest(searchValue, page, cancelToken, listener) {
        this.post('/api/product/search-products-and-rest', [page], {
            content: searchValue
        }, listener, cancelToken);
    }

    getOnlyProducts(listener) {
        this.get('/api/product/get-only-products', [], listener);
    }

    getProductionStockUsage(request, listener) {
        this.post('/api/production/get-stock-usage', [], request, listener);
    }

    getProductMini(type, id, loadCostInfo, allowBundlesWithGroups, listener) {
        this.get('/api/product/get-mini', [type, id, loadCostInfo, allowBundlesWithGroups], listener);
    }

    createStockFlow(stockFlow, listener) {
        this.post('/api/stock-flow/create-stock-flow', [], stockFlow, listener);
    }

    setSalesOrderAwbNo(orderId, awbNo, listener) {
        this.post('/api/sales-order/set-awb', [orderId], { content: awbNo }, listener);
    }

    createTransaction(transaction, listener) {
        this.post('/api/transaction/create-transaction', [], transaction, listener);
    }

    updateTransaction(transaction, listener) {
        this.post('/api/transaction/update-transaction', [], transaction, listener);
    }

    getProductSalesReportEndpointsList(listener) {
        this.get('/api/performance-report/get-product-report-endpoints', [], listener)
    }

    getStockFlowEndpointsList(listener) {
        this.get('/api/stock-flow/get-endpoints-list', [], listener);
    }

    updateJournalEntryTags(req, listener) {
        this.post('/api/journal-entry/update-entry-tags', [], req, listener)
    }

    getAllActiveMonitoring(listener) {
        this.get('/api/monitoring/get-all-active-monitoring', [], listener);
    }

    getTerminalActiveMonitoring(terminalId, listener) {
        this.get('/api/monitoring/get-active-monitoring', [terminalId], listener);
    }

    trackSecondDisplayTerminal(terminalId, listener) {
        this.get('/api/public/pos-second-display/track-terminal', [terminalId], listener);
    }

    getActiveMonitoring(listener) {
        this.get('/api/monitoring/get-active-monitoring', [getTerminal().id], listener);
    }

    collectingCargoReceiptNote(id, listener) {
        this.get('/api/cargo/receipt-note/collecting', [id], listener);
    }

    postCargoReceiptNote(id, listener) {
        this.get('/api/cargo/receipt-note/post', [id], listener);
    }

    postCargoTransferFirst(id, listener) {
        this.get('/api/cargo/receipt-note/post-cargo-issue-for-transfer-first', [id], listener);
    }

    postCargoTransfer(id, listener) {
        this.get('/api/cargo/receipt-note/post-cargo-issue-for-transfer', [id], listener);
    }

    getCargoInvItem(id, listener) {
        this.get('/api/cargo/item/get', [id], listener);
    }

    cargoCustomer = {
        getIndex: listener => this.get('/api/cargo-customer-user/index', [], listener)
    }

    getStockFlowInvoice(stockFlowId, listener) {
        this.get('/api/invoice/get-invoice', [stockFlowId], listener);
    }

    getSaleInvoice(saleId, listener) {
        this.get("/api/invoice/get-invoice-for-sale", [saleId], listener);
    }

    getQuotationInvoice(quotationId, salesOrder, listener) {
        this.get("/api/invoice/get-invoice-for-quotation", [quotationId, salesOrder], listener);
    }

    setSaleWorkState(workId, status, listener) {
        this.get('/api/sales/set-work-state', [workId, status], listener)
    }

    async asyncSetSaleWorkState(workId, status) {
        return new Promise((resolve, reject) => this.setSaleWorkState(workId, status, response => {
            if (response.status === true) {
                resolve(response.payload)
            } else {
                reject({ roboErpApiErr: true, msg: response.message })
            }
        }))
    }

    setSaleWorkShipmentTrackingNo(workId, shipmentTrackingNo, listener) {
        this.post("/api/sales/set-work-shipment-tracking-no", [workId], { content: shipmentTrackingNo }, listener)
    }

    async asyncSetSaleWorkShipmentTrackingNo(workId, shipmentTrackingNo) {
        return new Promise((resolve, reject) => this.setSaleWorkShipmentTrackingNo(workId, shipmentTrackingNo, response => {
            if (response.status === true) {
                resolve(response.payload)
            } else {
                reject({ roboErpApiErr: true, msg: response.message })
            }
        }))
    }

    getSalesOrders(req, listener) {
        this.post('/api/sales-order/get-sales-orders', [], req, listener);
    }

    getReportResult(reportName, request, listener) {
        this.post('/api/reporting/get-result', [reportName], request, listener);
    }

    getReportInfo(reportName, listener) {
        this.get('/api/reporting/get-report-info', [reportName], listener);
    }

    getReportBase(reportName, savedReportId, listener) {
        this.get('/api/reporting/get-report-base', [reportName, savedReportId], listener);
    }

    getReportEndpoint(reportName, listener) {
        this.get('/api/reporting/get-endpoint', [reportName], listener);
    }

    saveReportDesign(reportName, title, showForAll, offeringToShowForAll, state, listener) {
        this.post('/api/reporting/save-report', [reportName], {
            title, showForAll, offeringToShowForAll,
            state: state && Object.keys(state).length > 0 ? JSON.stringify(state) : ""
        }, listener)
    }

    renameReportDesign(designId, showForAll, offeringToShowForAll, title, listener) {
        this.post('/api/reporting/rename-report', [designId, showForAll, offeringToShowForAll], { content: title }, listener)
    }

    getReportingDashboard(listener) {
        this.get("/api/reporting/get-dashboard", [], listener);
    }

    deleteReportDesign(designId, listener) {
        this.get('/api/reporting/delete-report', [designId], listener)
    }

    saveReportDesignState(designId, state, listener) {
        this.post('/api/reporting/post-saved-report-state', [designId], {
            state: state && Object.keys(state).length > 0 ? JSON.stringify(state) : ""
        }, listener)
    }

    getReportInit(reportName, reportRequest, listener) {
        this.post('/api/reporting/get-report-init', [reportName], reportRequest, listener);
    }

    getSalesOrdersFilterLists(listener) {
        this.get('/api/sales-order/get-sales-orders-filter-lists', [], listener)
    }

    rateTransaction(transactionId, posSessionId, rating, listener) {
        this.get('/api/rate/rate-transaction', [transactionId, posSessionId, rating], listener);
    }

    getStockFlowItem(stockFlowId, listener) {
        this.get('/api/stock-flow/get-stock-flow-item', [stockFlowId], listener);
    }

    getSalesStockFlowMiniItem(stockFlowRecordId, listener) {
        this.get('/api/stock-flow/get-sales-stock-flow-mini-item', [stockFlowRecordId], listener);
    }

    getPurchaseStockFlowMiniItem(stockFlowRecordId, listener) {
        this.get('/api/stock-flow/get-purchase-stock-flow-mini-item', [stockFlowRecordId], listener);
    }

    getTransactionItem(transactionId, listener) {
        this.get('/api/transaction/get-transaction-item', [transactionId], listener);
    }

    getPosSessionItem(posSessionId, listener) {
        this.get('/api/pos-reporting/get-pos-session-item', [posSessionId], listener);
    }

    completeQuotationSales(stockFlowId, paymentMethods, listener) {
        this.post('/api/stock-flow/complete-quotation-sales', [stockFlowId], paymentMethods, listener)
    }

    getAllTransactions(page, req, listener) {
        this.post('/api/transaction/get-all-transactions', [], { page, ...req }, listener);
    }

    getDeliverables(customerId, listener) {
        this.get('/api/sales-order/get-deliverables', [customerId], listener);
    }

    getPartyTransactions(partyId, listener) {
        this.get('/api/transaction/get-party-transactions', [partyId], listener);
    }

    getProductAttributeOptions(groupId, listener) {
        this.get('/api/product-group/get-product-attribute-options', [groupId], listener);
    }

    getProductTransactions(productId, listener) {
        this.get('/api/transaction/get-product-transactions', [productId], listener);
    }

    getAllStockFlows(page, request, listener) {
        this.post('/api/stock-flow/get-all-stock-flows', [], { page, ...request }, listener);
    }

    getQuotations(listener) {
        this.get('/api/stock-flow/get-quotations', [], listener);
    }

    getLocationStockFlows(locationId, listener) {
        this.get('/api/stock-flow/get-location-stock-flows', [locationId], listener);
    }

    getGeneralLedgerReportWithReq(accountId, req, listener) {
        this.post('/api/accounting/get-general-ledger-report', [accountId], req, listener);
    }

    getGeneralLedgerReport(accountId, listener) {
        this.post('/api/accounting/get-general-ledger-report', [accountId], {}, listener);
    }

    getSubsidiaryLedgerReport(accountId, subsidiaryId, subsidiaryType, listener) {
        this.post('/api/accounting/get-general-ledger-report', [accountId, subsidiaryId, subsidiaryType], {}, listener);
    }

    getAllSubsidiaryLedgerReport(subsidiaryId, subsidiaryType, listener) {
        this.get('/api/accounting/get-subsidiary-ledger-report', [subsidiaryId, subsidiaryType], listener);
    }

    getBundlesContainingProduct(productId, listener) {
        this.get('/api/product-bundle/get-bundles-containing-product', [productId], listener)
    }

    getGroupsContainingProduct(productId, listener) {
        this.get('/api/product-group/get-groups-containing-product', [productId], listener)
    }

    getProductStockFlows(productId, listener) {
        this.get('/api/stock-flow/get-product-stock-flows', [productId], listener);
    }

    getSalesWorkCustomerTracking(hashId, listener) {
        this.get('/api/public/sales-work-tracking/get', [hashId], listener)
    }

    submitSalesWorkCustomerTrackingFeedback(hashId, rate, feedback, listener) {
        this.post('/api/public/sales-work-tracking/submit-feedback', [hashId], { rate, feedback }, listener)
    }

    updateLeadProfile(id, update, listener) {
        this.post('/api/lead/update-lead-profile', [id], update, listener)
    }

    updateLeadFlowInfo(id, update, listener) {
        this.post('/api/lead/update-lead-flow-info', [id], update, listener);
    }

    getStockReport(locationId, page, listener) {
        this.get('/api/sales-reporting/get-stock-report', [locationId, page], listener);
    }

    getPricePerformanceReportSales(ranges, useProductPrice, page, listener) {
        this.post('/api/performance-report/get-price-report-sales', [page], { ranges, useProductPrice }, listener);
    }

    getPricePerformanceReportProducts(ranges, useProductPrice, page, listener) {
        this.post('/api/performance-report/get-price-report-products', [page], { ranges, useProductPrice }, listener);
    }

    getPricePerformanceReport(ranges, useProductPrice, listener) {
        this.post('/api/performance-report/get-price-report', [], { ranges, useProductPrice }, listener);
    }

    getTimePerformanceReportSales(ranges, page, listener) {
        this.post('/api/performance-report/get-time-report-sales', [page], { ranges }, listener);
    }

    getTimePerformanceReportProducts(ranges, page, listener) {
        this.post('/api/performance-report/get-time-report-products', [page], { ranges }, listener);
    }

    getTimePerformanceReport(ranges, listener) {
        this.post('/api/performance-report/get-hourly-report', [], { ranges }, listener);
    }

    getLowStockProductReport(locationId, page, listener) {
        this.get('/api/products/get-low-stock-product-report', [locationId, page], listener);
    }

    getLmsAttendanceReport(page, listener) {
        this.get('/api/lms-attendance/get-attendance-record', [page], listener);
    }

    getMovieTicketAttendanceReport(page, listener) {
        this.get('/api/movie-ticket-attendance/get-attendance-record', [page], listener);
    }

    getProductStockReport(productId, listener) {
        this.get('/api/sales-reporting/get-product-stock-report', [productId], listener);
    }

    getIncomeReport(startDate, endDate, listener) {
        this.get('/api/transaction/get-income-report', [startDate, endDate], listener);
    }

    getExpenseReport(startDate, endDate, listener) {
        this.get('/api/transaction/get-expense-report', [startDate, endDate], listener);
    }

    getProfitReport(startDate, endDate, listener) {
        this.get('/api/transaction/get-profit-report', [startDate, endDate], listener);
    }

    getPOSSessionReport(startDate, endDate, cashClosedMode, listener) {
        this.get('/api/pos-reporting/get-pos-session-report', [startDate, endDate, cashClosedMode], listener);
    }

    getSalesSummaryReport(startDate, endDate, posMode, listener) {
        this.get('/api/sales-reporting/get-sales-summary-report', [startDate, endDate, posMode], listener);
    }

    getProductPerformance(startDate, endDate, posMode, listener) {
        this.get('/api/sales-reporting/get-product-performance', [startDate, endDate, posMode], listener);
    }

    getProductSalesReport(request, listener) {
        this.post('/api/performance-report/get-product-report', [], request, listener)
    }

    getSalesReport(startDate, endDate, posMode, customerId, salespersonId, listener) {
        this.get('/api/sales-reporting/get-sales-report', [startDate, endDate, posMode, customerId, salespersonId], listener);
    }

    getVenueSalesReport(startDate, endDate, venueId, combineInvoiceDocument, listener) {
        this.get('/api/venue-reporting/get-venue-sales-report', [startDate, endDate, venueId, combineInvoiceDocument], listener);
    }

    getPurchasesReport(startDate, endDate, listener) {
        this.get('/api/sales-reporting/get-purchases-report', [startDate, endDate], listener);
    }

    createJournalEntry(entry, listener) {
        this.post('/api/journal-entry/create-entry', [], entry, listener);
    }

    updateJournalEntry(entry, listener) {
        this.post('/api/journal-entry/update-entry', [], entry, listener);
    }

    stripeCreateIntentForServiceSale(id, amount, listener) {
        this.get('/api/online-payment/stripe/create-intent-for-service-sale', [id, amount], listener);
    }

    checkStudentForUnpaidInvoices(studentId, proof, listener) {
        this.compressImage(proof)
            .then(compressed => {
                const formData = new FormData();
                formData.append('proof', compressed);
                this.upload('/api/lms-attendance/check-student-for-unpaid-invoiced', [studentId], formData, listener);
            })
            .catch(() => listener(this.failedApiResponse()))
    }

    checkMovieTicket(ticketId, proof, listener) {
        this.compressImage(proof)
            .then(compressed => {
                const formData = new FormData();
                formData.append('proof', compressed);
                this.upload('/api/movie-ticket-attendance/check-ticket', [ticketId], formData, listener);
            })
            .catch(() => listener(this.failedApiResponse()))
    }


    getJournalEntryItem(entryId, listener) {
        this.get('/api/journal-entry/get-entry-item', [entryId], listener);
    }

    getAllJournalEntries(listener) {
        this.get('/api/journal-entry/get-all-entries', [], listener);
    }

    getJournalEntries(page, request, listener) {
        this.post("/api/journal-entry/get-entries", [page], request, listener)
    }

    getAccountTree(loadBalance, listener) {
        this.get('/api/accounting/get-account-tree', [loadBalance], listener);
    }

    getActivityBlueprint(id, listener) {
        this.get('/api/activity-blueprint/get-blueprint', [id], listener);
    }

    saveActivityBlueprint(data, listener) {
        this.post('/api/activity-blueprint/save-blueprint', [], data, listener);
    }

    getActivity(id, listener) {
        this.get('/api/activity/get-activity', [id], listener);
    }

    getActivityUsers(listener) {
        this.get('/api/activity-users/get', [], listener);
    }

    getCoursePaymentPackages(id, listener) {
        this.get('/api/course/get-course-payment-packages', [id], listener);
    }

    resolveLmsNotificationEmails(type, ids, listener) {
        this.post('/api/lms-notification/resolve-email-addresses', [type], ids, listener)
    }

    publishLmsNotification(request, listener) {
        this.post('/api/lms-notification/publish', [], request, listener);
    }

    getLmsNotificationRecord(id, listener) {
        this.get('/api/lms-notification/get-notification-record', [id], listener);
    }

    getLsmNotificationEndpoints(listener) {
        this.get('/api/lms-notification/get-endpoints', [], listener);
    }

    saveActivity(calendarMode, data, listener) {
        this.post('/api/activity/save-activity', [calendarMode], data, listener);
    }

    updateActivityDate(id, allDay, startDate, endDate, listener) {
        this.get('/api/activity/update-activity-date', [id, allDay, startDate, endDate], listener)
    }

    deleteActivity(id, deleteAll, listener) {
        this.get('/api/activity/delete-activity', [id, deleteAll], listener)
    }

    freeActivityFromCommonId(id, listener) {
        this.get('/api/activity/free-activity-from-common-id', [id], listener);
    }

    createTaxBill(receivable, payable, listener) {
        this.post('/api/tax/v2/create-tax-bill', [], { receivable, payable }, listener)
    }

    async asyncCreateTaxBill(receivable, payable) {
        return new Promise((resolve, reject) => this.createTaxBill(receivable, payable, response => {
            if (response.status === true) {
                resolve(response.payload)
            } else {
                reject({ roboErpApiErr: true, msg: response.message })
            }
        }))
    }

    listCustomResources(listener) {
        this.get('/api/custom/resource/list', [], listener);
    }

    deleteCustomResource(id, listener) {
        this.get('/api/custom/resource/delete', [id], listener);
    }

    uploadCustomResource(file, listener) {
        const formData = new FormData();
        formData.append('file', file);
        this.upload('/api/custom/resource/upload', [], formData, listener);
    }

    getAppData(listener) {
        this.get('/api/client-data/get', [], listener);
    }

    activityUnassignMyself(id, listener) {
        this.get('/api/activity/unassign-myself', [id], listener)
    }

    getRestaurantComponents(storeId, listener) {
        this.get('/api/rest-tables/get-components', [storeId], listener);
    }

    saveRestaurantComponents(storeId, update, listener) {
        this.post('/api/rest-tables/save-components', [storeId], update, listener);
    }

    getFoodRecipe(foodId, listener) {
        this.get('/api/recipe-manager/get-recipe', [foodId], listener);
    }

    saveFoodRecipe(foodId, recipe, listener) {
        this.post('/api/recipe-manager/save', [foodId], recipe, listener);
    }

    getAllFoods(listener) {
        this.get('/api/food/get-all', [], listener);
    }

    getMyStudentPresence(activityId, listener) {
        this.get('/api/student-presence/get-my-presence', [activityId], listener)
    }

    getMakeUpActivities(activityId, listener) {
        this.get('/api/student-presence/get-make-up-activities', [activityId], listener);
    }

    studentRegister(request, listener) {
        this.post('/api/student/register', [], request, listener);
    }

    getMyPayouts(status, listener) {
        this.get('/api/payout/get-my-payouts', [status], listener)
    }

    finalizeLeadStudent(leadId, request, listener) {
        this.post('/api/lead/finalize-student', [leadId], request, listener);
    }

    changePassword(request, listener) {
        this.post("/api/auth/change-password", [], request, listener)
    }

    setKotStatusOfItem(orderId, cartItemId, status, listener) {
        this.get('/api/pos/session/set-kot-status-of-item', [orderId, cartItemId, status], listener)
    }

    setOrderServiceDone(orderId, done, listener) {
        this.get('/api/pos/session/set-service-done', [orderId, done], listener)
    }

    setKotStatus(orderId, kotNo, status, listener) {
        this.get('/api/pos/session/set-kot-status', [orderId, kotNo, status], listener)
    }

    confirmSalesOrderModification(salesOrderId, changingCartItemQtyReqs, listener) {
        this.post('/api/pos/session/confirm-sales-order-modification', [salesOrderId], changingCartItemQtyReqs, listener);
    }

    updateSalesOrderAddress(salesOrderId, update, listener) {
        this.post('/api/sales-order/update-address', [salesOrderId], update, listener)
    }

    updateSalesOrderSerialNumbers(salesOrderId, update, listener) {
        this.post('/api/sales-order/update-serial-number', [salesOrderId], update, listener)
    }

    applySalesOrderCoupon(salesOrderId, couponCode, listener) {
        this.get('/api/sales-order/apply-coupon', [salesOrderId, couponCode], listener)
    }

    applySalesOrderFreeFormDiscount(salesOrderId, request, listener) {
        this.post('/api/sales-order/apply-free-form-discount', [salesOrderId], request, listener);
    }

    getRestSalesOrderHistoryOverview(salesOrderId, listener) {
        this.get('/api/sales-order/rest/order-history', [salesOrderId], listener);
    }

    updateOrderNo(salesOrderId, newOrderNo, listener) {
        this.get('/api/sales-order/update-order-no', [salesOrderId, newOrderNo], listener)
    }

    updateOrderDate(salesOrderId, update, listener) {
        this.post('/api/sales-order/update-date', [salesOrderId], update, listener)
    }

    removeSalesOrderDiscount(salesOrderId, discount, listener) {
        this.get('/api/sales-order/remove-discount', [salesOrderId, discount], listener)
    }

    cancelMyStudentPresence(activityId, listener) {
        this.get('/api/student-presence/cancel-my-presence', [activityId], listener);
    }

    createStudentPresence(activityId, state, makeUpActivityId, listener) {
        this.post('/api/student-presence/create-presence', [], { activityId, state, makeUpActivityId }, listener);
    }

    studentJoinSession(activityId, listener) {
        this.get('/api/student-presence/join-session', [activityId], listener)
    }

    createStudentPresenceConfirmation(activityId, state, studentId, note, listener) {
        this.post('/api/student-presence/confirm-presence', [], { activityId, state, studentId, note }, listener);
    }

    cancelStudentPresenceConfirmation(id, listener) {
        this.get('/api/student-presence/cancel-confirmation', [id], listener);
    }

    getLeadFlowCreationEndpoints(listener) {
        this.get('/api/lead/get-flow-endpoints', [], listener);
    }

    createLeadFlow(fullName, sourceId, customerId, assignations, listener) {
        this.post("/api/lead/create-flow", [], { fullName, sourceId, assignations, customerId }, listener);
    }

    getLeadFlow(id, listener) {
        this.get('/api/lead/get-flow', [id], listener);
    }

    getMyLeads(listener) {
        this.get('/api/lead/get-my-leads', [], listener);
    }

    getAllLeads(listener) {
        this.get('/api/lead/get-all-leads', [], listener);
    }

    getProspectProperties(leadId, listener) {
        this.get('/api/lead-prospect-property/get-items', [leadId], listener)
    }

    saveProspectProperty(item, listener) {
        this.post("/api/lead-prospect-property/save-item", [], item, listener);
    }

    getProspectCourses(leadId, listener) {
        this.get('/api/lead-prospect-course/get-items', [leadId], listener)
    }

    getMyCourses(listener) {
        this.get('/api/course/get-my-courses', [], listener);
    }

    saveProspectCourse(item, listener) {
        this.post("/api/lead-prospect-course/save-item", [], item, listener);
    }

    setLeadStatus(leadId, status, reason, listener) {
        this.post('/api/lead/set-status', [leadId, status], reason, listener)
    }

    deleteActivityBlueprint(id, listener) {
        this.get('/api/activity-blueprint/delete-blueprint', [id], listener)
    }

    getActivityBlueprintInputFields(id, listener) {
        this.get('/api/activity/get-blueprint-input-fields', [id], listener)
    }

    getShipmentItemTypeBlueprintInputFields(id, listener) {
        this.get('/api/activity/get-shipment-item-type-blueprint-input-fields', [id], listener)
    }

    getShipmentPackageTypeBlueprintInputFields(id, listener) {
        this.get('/api/activity/get-shipment-package-type-blueprint-input-fields', [id], listener)
    }

    getActivitySupportedStatuses(id, listener) {
        this.get('/api/activity/get-activity-supported-statuses', [id], listener);
    }

    setActivityStatus(activityId, statusId, formFieldValues, listener) {
        this.post('/api/activity-status/set-activity-status', [], { activityId, statusId, formFieldValues }, listener)
    }

    getActivityStatusHistory(activityId, listener) {
        this.get('/api/activity-status/set-activity-status-history', [activityId], listener);
    }

    getCalendarEvents(start, end, leadId, courseId, listener) {
        this.get('/api/calendar/get-events', [start, end, leadId, courseId], listener)
    }

    getTutorOptions(listener) {
        this.get('/api/tutor/get-tutor-options', [], listener);
    }

    getCinemaSeatQrCode(seatPlanId, row, col, listener) {
        this.get('/api/cinema-pos/get-seat-qr-code', [seatPlanId, row, col], listener)
    }

    getCinemaSeatPlans(listener) {
        this.get('/api/cinema/get-seat-plans', [], listener);
    }

    getSeatPlanEndpoints(seatPlanId, listener) {
        this.get('/api/seat-plan-designer/get-endpoints', [seatPlanId], listener);
    }

    saveSeatPlanDesign(seatPlanId, seats, listener) {
        this.post('/api/seat-plan-designer/save-plan', [seatPlanId], seats, listener);
    }

    getShowtimeSchedulerEndpoints(listener) {
        this.get('/api/showtime-scheduler/get-endpoints', [], listener);
    }

    getShowtimeTimeline(listener) {
        this.get('/api/showtime-scheduler/get-schedule-timeline', [], listener)
    }

    getShowtimeSchedules(cinemaId, day, listener) {
        this.get('/api/showtime-scheduler/get-schedules', [cinemaId, day], listener);
    }

    createBulkShowtimeSchedule(schedules, listener) {
        this.post('/api/showtime-scheduler/create-bulk-schedule', [], schedules, listener);
    }

    createShowtimeSchedule(schedule, listener) {
        this.post('/api/showtime-scheduler/create-schedule', [], schedule, listener);
    }

    showtimeScheduleTimeToDate(day, time, listener) {
        this.get('/api/showtime-scheduler/time-to-date', [day, time], listener)
    }

    continueShowtimeSchedule(scheduleId, listener) {
        this.get('/api/showtime-scheduler/continue-schedule', [scheduleId], listener);
    }

    deleteShowtimeSchedule(scheduleId, listener) {
        this.get('/api/showtime-scheduler/delete-schedule', [scheduleId], listener);
    }

    getMyBoardViews(parentId, parentType, listener) {
        this.get('/api/to-do-board/get-my-board-views', [parentId, parentType], listener);
    }

    getToDoBoardView(id, parentId, parentType, listener) {
        this.get("/api/to-do-board/get-board-view", [id, parentId, parentType], listener);
    }

    saveToDoBoardView(parentId, parentType, update, listener) {
        this.post('/api/to-do-board/save-board-view', [parentId, parentType], update, listener);
    }

    getBoardView(recordId, listener) {
        this.get("/api/board-view/get", [recordId], listener)
    }

    saveBoardView(recordId, content, listener) {
        this.post('/api/board-view/save', [recordId], content, listener);
    }

    getTutoringCoursesOptions(listener) {
        this.get('/api/tutor/get-tutoring-courses-options', [], listener);
    }

    getComments(parentType, parentId, page, listener) {
        this.get("/api/comment/get-comments", [parentType, parentId, page], listener);
    }

    postComment(parentType, parentId, content, listener) {
        this.post("/api/comment/post-comment", [parentType, parentId], { content }, listener);
    }

    deleteComment(id, listener) {
        this.get("/api/comment/delete-comment", [id], listener);
    }

    getSingleDayStatement(type, date, listener) {
        this.get('/api/accounting/get-single-day-statement', [type, date], listener);
    }

    getRangeStatement(type, startDate, endDate, listener) {
        this.get('/api/accounting/get-single-day-statement', [type, startDate + '-' + endDate], listener);
    }

    getProductsFromIds(ids, listener) {
        this.post('/api/product/get-products-from-ids', [], ids, listener);
    }

    getProductBundlesFromIds(ids, listener) {
        this.post('/api/product/get-product-bundles-from-ids', [], ids, listener);
    }

    getProductBarcodes(productIds, listener) {
        this.post('/api/product/get-products-barcode', [], productIds, listener);
    }

    getBundleBarcodes(bundleIds, listener) {
        this.post('/api/product/get-bundles-barcode', [], bundleIds, listener);
    }

    getUnpaidSales(customerId, listener) {
        this.get('/api/stock-flow/get-unpaid-sales', [customerId], listener);
    }

    getV2UnpaidSales(customerId, listener) {
        this.get('/api/v2/sales/unpaid', [customerId], listener);
    }

    getV2UnpaidBills(vendorId, listener) {
        this.get('/api/v2/bills/unpaid', [vendorId], listener);
    }

    getUnpaidPurchases(supplierId, listener) {
        this.get('/api/stock-flow/get-unpaid-purchases', [supplierId], listener);
    }

    getUnpaidVenueSales(venueId, listener) {
        this.get('/api/stock-flow/get-unpaid-venue-sales', [venueId], listener);
    }

    getFundTransfer(id, listener) {
        this.get("/api/fund-transfer/get", [id], listener)
    }

    createFundTransfer(fundTransfer, listener) {
        this.post("/api/fund-transfer/create", [], fundTransfer, listener);
    }

    deleteFundTransfer(id, listener) {
        this.get('/api/fund-transfer/delete', [id], listener)
    }

    bounceCheque(chequeId, listener) {
        this.get('/api/cheque/bounce', [chequeId], listener);
    }

    depositCheque(chequeId, listener) {
        this.get('/api/cheque/deposit', [chequeId], listener);
    }

    getChequesWithUnusedAmounts(listener) {
        this.get('/api/cheque/get-cheques-with-unused-amounts', [], listener);
    }

    getCheque(chequeId, listener) {
        this.get('/api/cheque/get', [chequeId], listener);
    }

    getAllSalespeople(listener) {
        this.get('/api/salesperson/get-all', [], listener);
    }

    getAllWaiters(listener) {
        this.get('/api/waiter/get-all', [], listener);
    }

    getAllSystemLogs(listener) {
        this.get('/api/system-logs/get-all-logs', [], listener);
    }

    getRelatedSystemLogs(id, listener) {
        this.get('/api/system-logs/get-related-object-logs', [id], listener);
    }

    deleteTransaction(id, listener) {
        this.get('/api/transaction/delete-transaction/' + id, [], listener);
    }

    deleteStockFlow(id, listener) {
        this.get('/api/stock-flow/delete-stock-flow/' + id, [], listener);
    }

    importData(objectType, dataFile, listener) {
        const formData = new FormData();
        formData.append('dataFile', dataFile)
        this.upload('/api/data-import/import-data', [objectType], formData, listener);
    }

    sendReceiptEmail(stockFlowRecordId, email, listener) {
        if (email) {
            this.get('/api/email/send-receipt-email', [stockFlowRecordId, email], listener);
        } else {
            this.get('/api/email/send-receipt-email', [stockFlowRecordId], listener);
        }
    }

    sendCustomerCardEmail(customerId, email, listener) {
        if (email) {
            this.get('/api/email/send-customer-card-email', [customerId, email], listener);
        } else {
            this.get('/api/email/send-customer-card-email', [customerId], listener);
        }
    }

    getPosSalesSummaryReport(posSessionId, listener) {
        this.get('/api/pos-reporting/get-sales-summary-report', [posSessionId], listener)
    }

    getDashboardUnpaidInvoices(listener) {
        this.get('/api/dashboard/get-unpaid-invoices', [], listener)
    }

    getStockFlowGeneratedCoupons(stockFlowId, listener) {
        this.get('/api/stock-flow/get-stock-flow-generated-coupons', [stockFlowId], listener);
    }

    getFnbMenuEndpoint(listener) {
        this.get('/api/fnb-event-menu/endpoint', [], listener)
    }

    getFnbMenuSections(menuId, withEndpoint, listener) {
        this.get('/api/fnb-event-menu/get', [menuId, withEndpoint], listener)
    }

    saveFnbMenuSections(menuId, sections, listener) {
        this.post('/api/fnb-event-menu/save', [menuId], sections, listener)
    }

    getFnbEventEndpoint(listener) {
        this.get('/api/fnb-event/endpoint', [], listener)
    }

    getFnbEventAndLock(id, listener) {
        this.get('/api/fnb-event/get-and-lock', [id], listener)
    }

    createFnbEvent(create, listener) {
        this.post('/api/fnb-event/create', [], create, listener)
    }

    updateFnbEvent(update, listener) {
        this.post('/api/fnb-event/update', [], update, listener)
    }

    getDashboard(listener) {
        this.get('/api/dashboard/get', [], listener)
    }

    getDashboardRealEstate(listener) {
        this.get('/api/dashboard-real-estate/get', [], listener)
    }

    getDashboardRestaurant(request, listener) {
        this.post('/api/dashboard-restaurant/get', [], request, listener)
    }

    getDashboardRestaurantEndpoints(listener) {
        this.get('/api/dashboard-restaurant/endpoints', [], listener);
    }

    getRestBusinessSummaryEndpoints(listener) {
        this.get('/api/rest-business-summary/endpoints', [], listener)
    }

    getRestBusinessSummaryReport(request, listener) {
        this.post('/api/rest-business-summary/report', [], request, listener)
    }

    getDashboard2(listener) {
        this.get('/api/dashboard2/get', [], listener)
    }

    getRetailLmsDashboard(listener) {
        this.get('/api/all-dashboard/retail-lms', [], listener);
    }

    openNewCustomerDialog(terminalId, open, listener) {
        this.get('/api/pos/session/open-new-customer-dialog', [terminalId, open], listener);
    }

    getMyStudents(courseId, listener) {
        this.get('/api/course/get-my-students', [courseId], listener)
    }

    getInvBatchBarcodes(batchNo, listener) {
        this.get("/api/inventory-batch/get-product-barcodes", [encodeURIComponent(batchNo)], listener)
    }

    getInvBatchNos(listener) {
        this.get('/api/inventory-batch/get-batch-nos', [], listener)
    }

    saveIngredient(ingredient, listener) {
        this.post('/api/ingredient/save', [], ingredient, listener);
    }

    getIngredient(id, listener) {
        this.get('/api/ingredient', [id], listener);
    }

    saveFood(food, listener) {
        this.post('/api/food/save', [], food, listener);
    }

    setOrderTable(orderId, tableId, listener) {
        this.get('/api/rest-order/set-table', [orderId, tableId], listener)
    }

    getFood(id, listener) {
        this.get('/api/food', [id], listener);
    }

    getRestOrders(storeId, listener) {
        this.get("/api/rest-order/get-orders", [storeId], listener);
    }

    getActiveCarts(storeId, listener) {
        this.get("/api/rest-order/get-active-carts", [storeId], listener);
    }

    getMyTutors(courseId, listener) {
        this.get('/api/course/get-my-tutors', [courseId], listener)
    }

    getTutorAvailabilityReport(courseId, tutorId, listener) {
        this.get('/api/course/get-tutor-availability-report', [courseId, tutorId], listener);
    }

    getUpcomingStudentAttendanceReport(courseId, studentId, listener) {
        this.get('/api/course/get-student-attendance-report', [courseId, studentId], listener);
    }

    getMySessionHistoryInCourse(courseId, listener) {
        this.get('/api/course/get-my-history', [courseId], listener)
    }

    getEmailEvents(listener) {
        this.get('/api/email-event/list', [], listener);
    }

    deleteEmailEvent(id, listener) {
        this.get('/api/email-event/delete', [id], listener);
    }

    updateEmailEvent(update, listener) {
        this.post('/api/email-event/update', [], update, listener);
    }

    getCurrencies(listener) {
        this.get('/api/currency/get-currencies', [], listener)
    }

    async asyncGetCurrencies() {
        return new Promise((resolve, reject) => this.getCurrencies(response => {
            if (response.status === true) {
                resolve(response.payload)
            } else {
                reject(response.message)
            }
        }))
    }

    getManyProductMini(items, listener) {
        this.post("/api/product/get-many-mini", [], items, listener)
    }

    async asyncGetManyProductMini(items) {
        return new Promise((resolve, reject) => this.getManyProductMini(items, response => {
            if (response.status === true) {
                resolve(response.payload)
            } else {
                reject(response.message)
            }
        }))
    }

    importStockFlowItems(dataFile, listener) {
        const formData = new FormData();
        formData.append('dataFile', dataFile)
        this.upload('/api/stock-flow-item/import-items', [], formData, listener);
    }

    importStockAdjustment(dataFile, listener) {
        const formData = new FormData();
        formData.append('dataFile', dataFile)
        this.upload('/api/stock-adjustment/import-items', [], formData, listener);
    }

    getCustomerAdvanceBalance(customerId, listener) {
        this.get('/api/advance-payment/customer-balance', [customerId], listener)
    }

    async asyncGetCustomerAdvanceBalance(customerId) {
        return new Promise((resolve, reject) => this.getCustomerAdvanceBalance(customerId, response => {
            if (response.status === true) {
                resolve(response.payload)
            } else {
                reject(response.message)
            }
        }))
    }

    getPrintEvents(listener) {
        this.get('/api/print-event/list', [], listener);
    }

    deletePrintEvent(id, listener) {
        this.get('/api/print-event/delete', [id], listener);
    }

    updatePrintEvent(update, listener) {
        this.post('/api/print-event/update', [], update, listener);
    }

    getAvailableMultiEscPrinters(listener) {
        this.get('/api/multi-esc-print/available-printers', [], listener);
    }

    multiEscDocumentPrint(printerName, base64Data, listener) {
        this.post('/api/multi-esc-print/print', [], { printerName, base64Data }, listener);
    }

    printRestKot(type, printerName, request, listener) {
        this.post('/api/multi-esc-print/print-rest-kot', [type, printerName], request, listener)
    }

    async asyncPrintRestKot(type, printerName, request) {
        return new Promise((resolve, reject) => this.printRestKot(type, printerName, request, response => {
            if (response.status === true) {
                resolve(response.payload)
            } else {
                reject(response.message)
            }
        }))
    }

    async asyncGetAvailableMultiEscPrinters() {
        return new Promise((resolve, reject) => this.getAvailableMultiEscPrinters(response => {
            if (response.status === true) {
                resolve(response.payload)
            } else {
                reject(response.message)
            }
        }))
    }

    async asyncMultiEscDocumentPrint(printerName, base64Data) {
        return new Promise((resolve, reject) => this.multiEscDocumentPrint(printerName, base64Data, response => {
            if (response.status === true) {
                resolve(response.payload)
            } else {
                reject(response.message)
            }
        }))
    }

    async asyncSetPaymentMethodType(id, type) {
        return new Promise((resolve, reject) => this.setPaymentMethodType(id, type, response => {
            if (response.status === true) {
                resolve(response.payload)
            } else {
                reject(response.message)
            }
        }))
    }

    setPaymentMethodType(id, type, listener) {
        this.get('/api/transaction/set-payment-method-type', [id, type], listener);
    }

    setUpdateValue(terminalId, property, value, updateId, listener) {
        this.post('/api/pos/session/set-update-value', [terminalId, property, updateId], {
            content: value
        }, listener);
    }

    updateNewDeliverableSmsNumber(number, listener) {
        this.post("/api/settings/update-new-deliverable-sms-number", [], { content: number }, listener);
    }

    getNewDeliverableSmsNumber(listener) {
        this.get('/api/settings/get-new-deliverable-sms-number', [], listener);
    }

    getOnlineStoreBlockMessage(listener) {
        this.get('/api/settings/get-online-store-block-message', [], listener);
    }

    updateOnlineStoreBlockMessage(message, listener) {
        this.post("/api/settings/update-online-store-block-message", [], { content: message }, listener);
    }

    getSalesOrder(salesOrderId, listener) {
        this.get('/api/sales-order/get-order', [salesOrderId], listener);
    }

    async asyncGetSalesOrder(salesOrderId) {
        return new Promise((resolve, reject) => this.getSalesOrder(salesOrderId, response => {
            if (response.status === true) {
                resolve(response.payload)
            } else {
                reject("Could not load order to print")
            }
        }))
    }

    getSalesMobileAppVersion(listener) {
        this.get('/api/mobile-app-info/get-sales-app-version', [], listener)
    }

    openCustomerQrCodeDialog(terminalId, listener) {
        this.get('/api/pos/session/open-customer-qr-code-dialog', [terminalId], listener);
    }

    closeCustomerQrCodeDialog(terminalId, listener) {
        this.get('/api/pos/session/close-customer-qr-code-dialog', [terminalId], listener);
    }

    openCustomerSearchDialog(terminalId, open, listener) {
        this.get('/api/pos/session/open-customer-search-dialog', [terminalId, open], listener);
    }

    logCashDrawerOpen() {
        this.get('/api/system-logs/log/cash-drawer-open', [], () => { });
    }

    getPaymentMethodInfo(listener) {
        this.get('/api/payment-method/get-payment-method-info', [], listener);
    }

    async asyncGetPaymentMethodInfo() {
        return new Promise((resolve, reject) => this.getPaymentMethodInfo(response => {
            if (response.status === true) {
                resolve(response.payload)
            } else {
                reject(response.message)
            }
        }))
    }

    saveStateDraft(draft, listener) {
        this.post('/api/state-draft/save-draft', [], draft, listener);
    }

    getMyStateDrafts(stateType, listener) {
        this.get('/api/state-draft/get-my-drafts', [stateType], listener);
    }

    getAllStateDrafts(stateType, listener) {
        this.get('/api/state-draft/get-all-drafts', [stateType], listener);
    }

    deleteStateDraft(draftId, listener) {
        this.get('/api/state-draft/delete-draft', [draftId], listener);
    }

    addTicketToSession(request, listener) {
        this.post('/api/cinema-pos/add-ticket-to-session', [], request, listener);
    }

    getCinemaPosMovies(listener) {
        this.get('/api/cinema-pos/get-movies', [], listener)
    }

    getCinemaPosSelectionOverview(movieId, showtimeId, listener) {
        this.get('/api/cinema-pos/get-selection-overview', [movieId, showtimeId], listener);
    }

    getCinemaPosMovieSchedules(terminalId, movieId, day, listener) {
        this.get('/api/cinema-pos/get-schedules', [terminalId, movieId, day], listener);
    }

    getCinemaPosShowtimeEndpoints(movieId, listener) {
        this.get('/api/cinema-pos/get-endpoints', [movieId], listener);
    }

    getCinemaPosSeatPlan(showtimeId, listener) {
        this.get('/api/cinema-pos/get-seat-plan', [showtimeId], listener);
    }

    createTemplate(type, listener) {
        this.get("/api/terminal/create-template", [type], listener)
    }

    duplicateTemplate(templateId, listener) {
        this.get("/api/terminal/duplicate-template", [templateId], listener)
    }

    getTemplate(templateId, listener) {
        this.get("/api/terminal/get-template", [templateId], listener)
    }

    saveTemplate(update, listener) {
        this.post("/api/terminal/save-template", [], update, listener)
    }

    deleteTemplate(templateId, listener) {
        this.get("/api/terminal/delete-template", [templateId], listener)
    }

    getTemplates(listener) {
        this.get('/api/templates/get-templates', [], listener);
    }

    setTemplateDefault(templateId, templateTypeDefault, listener) {
        this.get('/api/templates/set-template-default', [templateId, templateTypeDefault], listener);
    }

    getCogsCalcPagePayload(listener) {
        this.get('/api/cogs-job/page-payload', [], listener)
    }

    submitCogsCalcTaskJob(request, listener) {
        if (isV2()) {
            this.post("/api/cogs-job/v2/recalculate", [], request, listener);
        } else {
            this.post("/api/cogs-job/recalculate", [], request, listener);
        }
    }

    getMarkupTemplates(listener) {
        this.get('/api/markup-template/templates', [], listener);
    }

    loadMarkupTemplateSrc(defName, templ, listener) {
        this.get('/api/markup-template/templates/load-src', [defName, templ], listener);
    }

    saveMarkupTemplateSrc(templ, src, listener) {
        this.post('/api/markup-template/templates/save-src', [templ], { content: src }, listener);
    }

    createMarkupTemplate(defName, listener) {
        this.get('/api/markup-template/templates/create', [defName], listener);
    }

    deleteMarkupTemplate(id, listener) {
        this.get('/api/markup-template/templates/delete', [id], listener);
    }

    toggleStandardMarkupTemplateActive(id, listener) {
        this.get('/api/markup-template/templates/toggle-standard', [id], listener);
    }

    listCustomFragments(listener) {
        this.get('/api/custom/fragment/list', [], listener);
    }

    createCustomFragment(name, listener) {
        this.post('/api/custom/fragment/create', [], { content: name }, listener);
    }

    loadCustomFragmentSrc(fragmentId, listener) {
        this.get('/api/custom/fragment/src', [fragmentId], listener);
    }

    saveCustomFragmentSrc(fragmentId, src, listener) {
        this.post('/api/custom/fragment/src', [fragmentId], { content: src }, listener);
    }

    renameCustomFragment(fragmentId, name, listener) {
        this.post('/api/custom/fragment/rename', [fragmentId], { content: name }, listener);
    }

    deleteCustomFragment(fragmentId, listener) {
        this.get('/api/custom/fragment/delete', [fragmentId], listener);
    }

    downloadReportEngineExportExcel(reportName, requestId, listener) {
        return this.downloadGet('/api/reporting/export-to-excel', [reportName, requestId], listener)
    }

    downloadExportStockFlow(stockFlowId) {
        const url = this.createUrl('/api/export/stock-flow', [stockFlowId]);
        if (Util.isDebug()) {
            return "http://localhost:8080" + url;
        } else if (Util.isDebug2()) {
            return "http://192.168.0.50:8080" + url;
        } else {
            return url;
        }
    }

    reportEngineExportExcel(reportName, requestId) {
        const url = this.createUrl("/api/reporting/export-to-excel", [reportName, requestId]);
        if (Util.isDebug()) {
            return "http://localhost:8080" + url;
        } else {
            return url;
        }
    }

    getTemplateEnginePath(name, templ, id) {
        const url = this.createUrl('/api/markup-template/pdf', [name, templ, id]);
        if (Util.isDebug()) {
            return "http://localhost:8080" + url;
        } else {
            return url;
        }
    }

    getPublicTemplateEnginePath(name, templ, id) {
        const url = this.createUrl('/api/public/markup-template/pdf', [name, templ, id]);
        if (Util.isDebug()) {
            return "http://localhost:8080" + url;
        } else {
            return window.location.origin + url;
        }
    }

    getTemplateEnginePathPreview(name, templ) {
        const url = this.createUrl('/api/markup-template/pdf/preview', [name, templ]);
        if (Util.isDebug()) {
            return "http://localhost:8080" + url;
        } else {
            const secure = window.location.protocol !== "http:";
            if (!secure) {
                return window.location.origin.replace('3000', '8080') + url;
            }

            return url;
        }
    }

    redirectToOnlineStoreCustomPage(id) {
        return this.createUrl('/api/url/open-online-store-custom-page', [id]);
    }

    redirectToOnlineStoreBlog(blogId) {
        return this.createUrl('/api/url/open-online-store-blog', [blogId]);
    }

    downloadExportAccountTree() {
        return this.createUrl('/api/accounting/export-account-tree', [])
    }

    downloadImportDataTemplate(objectType) {
        return this.createUrl('/api/data-import/get-import-template', [objectType])
    }

    downloadNoteAttachment(noteId) {
        const url = this.createUrl('/api/list/download-note-attachment', [noteId]);
        if (Util.isDebug()) {
            return "http://localhost:8080" + url;
        } else if (Util.isDebug2()) {
            return "http://192.168.0.50:8080" + url;
        } else {
            return url;
        }
    }

    downloadStockAdjustmentImportTemplate() {
        const url = this.createUrl('/api/stock-adjustment/get-import-template', []);
        if (Util.isDebug()) {
            return "http://localhost:8080" + url;
        } else if (Util.isDebug2()) {
            return "http://192.168.0.50:8080" + url;
        } else {
            return url;
        }
    }

    downloadStockFlowItemImportTemplate() {
        const url = this.createUrl('/api/stock-flow-item/get-import-template', []);
        if (Util.isDebug()) {
            return "http://localhost:8080" + url;
        } else if (Util.isDebug2()) {
            return "http://192.168.0.50:8080" + url;
        } else {
            return url;
        }
    }

    downloadUploadFieldFile(id) {
        const url = this.createUrl('/api/upload-field/download-file', [id]);
        if (Util.isDebug()) {
            return "http://localhost:8080" + url;
        } else if (Util.isDebug2()) {
            return "http://192.168.0.50:8080" + url;
        } else {
            return url;
        }
    }

    downloadUploadFieldFileAsImage(id) {
        const url = this.createUrl('/api/upload-field/download-file-as-image', [btoa(id)]);
        if (Util.isDebug()) {
            return "http://localhost:8080" + url;
        } else if (Util.isDebug2()) {
            return "http://192.168.0.50:8080" + url;
        } else {
            return url;
        }
    }

    downloadAdvancedNoteAttachment(noteId) {
        return this.createUrl('/api/pro-design/download-node-attachment', [noteId]);
    }

    getThumbnail(objectType, objectId) {
        return this.createUrl('/api/list/get-thumbnail', [objectType, objectId])
    }

    getPublicThumbnail(objectType, objectId) {
        return this.createUrl('/api/public/list/get-thumbnail', [objectType, objectId])
    }

    getThumbnailForSure(objectType, objectId) {
        return this.createUrl('/api/list/get-thumbnail-for-sure', [objectType, objectId])
    }

    getMedia(objectType, objectId) {
        return this.createUrl('/api/list/get-media', [objectType, objectId])
    }

    getCustomResourceImagePath(id) {
        return this.createUrl('/api/custom/resource', [id]);
    }

    getMovieTicketWalletPass(ticketId) {
        return this.createUrl('/api/pass/get-movie-ticket-pass', [ticketId]);
        // return 'http://192.168.0.50:8080/api/pass/get-movie-ticket-pass/' + ticketId
    }

    getVideoMedia(objectType, objectId) {
        if (this.lang != undefined && this.lang != null) {
            return this.createUrl('/api/list/get-video-media', [objectType, objectId, this.lang])
        } else {
            return this.createUrl('/api/list/get-video-media', [objectType, objectId])
        }
    }

    getNoImagePlaceholder() {
        return this.createUrl('/api/image/get-no-image-placeholder', []);
    }

    getDynamicContentImage() {
        return this.createUrl('/api/image/get-dynamic-content-image', []);
    }

    getImage(id) {
        return this.createUrl('/api/image/get-image', [id]);
    }

    getCustomerCardPdf(id) {
        return this.createUrl('/api/pdf/get-customer-card', [id]);
    }

    getStudentAttendanceProof(id) {
        return this.createUrl('/api/lms-attendance/get-proof', [id]);
    }

    getMovieTicketAttendanceProof(id) {
        return this.createUrl('/api/movie-ticket-attendance/get-proof', [id]);
    }

    uploadFile(file, listener) {
        const formData = new FormData();
        formData.append('file', file);
        this.upload('/api/upload-field/upload-file', [], formData, listener);
    }

    uploadImage(image, listener) {
        this.compressImage(image)
            .then(compressedImage => {
                const formData = new FormData();
                formData.append('image', compressedImage);
                this.upload('/api/image/upload-image', [], formData, listener);
            })
            .catch(() => listener(this.failedApiResponse()))
    }

    post(url, params, body, listener) {
        this.callApi(this.createUrl(url, params), 'post', body, undefined, listener);
    }

    post(url, params, body, listener, cancelToken) {
        this.callApi(this.createUrl(url, params), 'post', body, undefined, listener, cancelToken);
    }

    get(url, params, listener) {
        this.callApi(this.createUrl(url, params), 'get', undefined, undefined, listener);
    }

    upload(url, params, formData, listener) {
        this.callApi(this.createUrl(url, params), 'post', undefined, formData, listener);
    }

    downloadGet(url, params, listener) {
        this.callApi(this.createUrl(url, params), 'get', undefined, undefined, listener, undefined, "blob");
    }

    postWithHeaders(url, params, body, listener, headers) {
        this.callApi(this.createUrl(url, params), 'post', body, undefined, listener, undefined, undefined, headers);
    }

    getServerPath() {
        return ''
        // return 'http://pos.4co.co:2021'
    }

    getWebSocketEndpoint() {
        return this.getServerPath() + '/api/web-socket';
        // return window.location.origin + '/api/socket'
    }

    getRawSocketUrl() {
        if (!Util.isDebug()) {
            const secure = window.location.protocol !== "http:";
            if (secure) {
                return `wss://${window.location.host}/api/web-socket`
            } else {
                return `ws://${window.location.host}/api/web-socket`
            }
        } else {
            return `ws://localhost:8080/api/web-socket`
        }
    }

    callApi(url, method, body, formData, listener, cancelToken, responseType, callApiHeaders) {
        const func = (otherHeaders = {}) => {
            // const account = getAccount();
            axios({
                url: this.getServerPath() + url,
                method,
                cancelToken: cancelToken ? cancelToken.token : undefined,
                data: formData ? formData : body,
                headers: {
                    "Content-Type": method == "post" ? (formData ? "multipart/form-data" : "application/json") : undefined,
                    // ...(account != null ? {
                    //     "pos-un": account.email,
                    //     "pos-pw": account.password,
                    // } : {}),
                    ...((this.lang != undefined && this.lang != null) ? {
                        "rbt-lang": this.lang
                    } : {}),
                    ...this.headers,
                    ...otherHeaders,
                    ...callApiHeaders
                },
                withCredentials: false,
                responseType
            })
                .then(async response => {
                    if (responseType === "blob") {
                        listener({ status: true, payload: response.data })
                    } else {
                        const result = response.data;
                        if (result.status === true || result.status === false) {
                            switch (result.message) {
                                case "CSTM_ERR_CLOSED_BOOKS":
                                    listener({ ...result, message: "Books already closed for the date specified." })
                                    break;
                                case "CSTM_ERR_CLOSED_BOOKS_INVALID_CLEARANCE":
                                    {
                                        UIUtil.showError('Invalid clearance code');
                                        const newClearanceCode = await UIUtil.promptClearanceCode("Please input admin clearance code to continue.");
                                        if (Util.isStringExists(newClearanceCode)) {
                                            func({ "rbt-authorize-clearance-code": newClearanceCode })
                                        } else {
                                            listener({ ...result, message: "Clearance code not submitted" })
                                        }
                                    }
                                    break;
                                case "CSTM_ERR_CLOSED_BOOKS_REQUIRE_CLEARANCE":
                                    {
                                        const clearanceCode = await UIUtil.promptClearanceCode("Books already closed for the date specified. Please input admin clearance code to continue.");
                                        if (Util.isStringExists(clearanceCode)) {
                                            func({ "rbt-authorize-clearance-code": clearanceCode })
                                        } else {
                                            listener({ ...result, message: "Clearance code not submitted" })
                                        }
                                    }
                                    break;
                                case "CSTM_ERR_SECURITY_CLEARANCE_INVALID_CLEARANCE":
                                    {
                                        UIUtil.showError('Invalid clearance code');
                                        const newClearanceCode = await UIUtil.promptClearanceCode("Please input admin / supervisor clearance code to continue.");
                                        if (Util.isStringExists(newClearanceCode)) {
                                            func({ "rbt-authorize-clearance-code": newClearanceCode })
                                        } else {
                                            listener({ ...result, message: "Clearance code not submitted" })
                                        }
                                    }
                                    break;
                                case "CSTM_ERR_SECURITY_CLEARANCE_REQUIRE_CLEARANCE":
                                    {
                                        const clearanceCode = await UIUtil.promptClearanceCode("Please input admin / supervisor clearance code to continue.");
                                        if (Util.isStringExists(clearanceCode)) {
                                            func({ "rbt-authorize-clearance-code": clearanceCode })
                                        } else {
                                            listener({ ...result, message: "Clearance code not submitted" })
                                        }
                                    }
                                    break;
                                default:
                                    if (result.message?.startsWith?.('CSTM_ERR_SECURITY_CLEARANCE_INVALID_CLEARANCE:')) {
                                        const promptInfo = result.message.split(":")[1];

                                        UIUtil.showError('Invalid clearance code');
                                        const newClearanceCode = await UIUtil.promptClearanceCode("Please input admin / supervisor clearance code to continue.", promptInfo);
                                        if (Util.isStringExists(newClearanceCode)) {
                                            func({ "rbt-authorize-clearance-code": newClearanceCode })
                                        } else {
                                            listener({ ...result, message: "Clearance code not submitted" })
                                        }

                                    } else if (result.message?.startsWith?.('CSTM_ERR_SECURITY_CLEARANCE_REQUIRE_CLEARANCE:')) {
                                        const promptInfo = result.message.split(":")[1];

                                        const clearanceCode = await UIUtil.promptClearanceCode("Please input admin / supervisor clearance code to continue.", promptInfo);
                                        if (Util.isStringExists(clearanceCode)) {
                                            func({ "rbt-authorize-clearance-code": clearanceCode })
                                        } else {
                                            listener({ ...result, message: "Clearance code not submitted" })
                                        }

                                    } else {
                                        listener(result)
                                    }
                                    break;
                            }
                        } else {
                            listener(this.failedApiResponse())
                        }
                    }
                })
                .catch(error => {
                    //console.log(error)
                    if (error.response && (error.response.status == 401 || error.response.status == 403)) {
                        clearAccount();
                        if (this.showLogoutMsg) {
                            alert("You have been logged out!");
                        }
                        window.location.href = "/";
                    } else if (error.response && error.response.status == 413) {
                        listener(this.failedApiResponse("File size exceeds maximum upload size!"))
                    } else {
                        console.log(error);
                        console.log(listener);
                        listener(this.failedApiResponse())
                    }
                });
        }
        func();
    }

    createUrl(url, params) {
        for (const param of params) {
            url += '/' + param;
        }
        return url;
    }

    failedApiResponse(message) {
        return {
            status: false,
            message: message !== undefined ? message : "An error occurred. Please try again later.",
            payload: null
        }
    }

    compressImage(image) {
        if (SKIP_IMAGE_COMPRESSION) {
            return new Promise((resolve) => resolve(image));
        }

        if (image !== undefined) {
            return new Promise((resolve, reject) => new Compressor(image, {
                maxWidth: 1028,
                maxHeight: 1028,
                quality: 0.8,
                success: (result) => resolve(result),
                error: (err) => reject(err)
            }));
        } else {
            return new Promise((resolve) => resolve(undefined));
        }
    }

    maybeCompressImage(image, skip) {
        if (SKIP_IMAGE_COMPRESSION || skip) {
            return new Promise((resolve) => resolve(image));
        }

        if (image !== undefined) {
            return new Promise((resolve, reject) => new Compressor(image, {
                maxWidth: 1028,
                maxHeight: 1028,
                quality: 0.8,
                success: (result) => resolve(result),
                error: (err) => reject(err)
            }));
        } else {
            return new Promise((resolve) => resolve(undefined));
        }
    }

    demo_getMovies(listener) {
        return this.get('/api/movie-demo/get-movies', [], listener)
    }

    demo_getShowtimeEndpoints(movieId, listener) {
        this.get('/api/movie-demo/get-endpoints', [movieId], listener);
    }

    demo_purchaseMovieTickets(req, listener) {
        this.post('/api/online-store/online-movie-ticket/purchase-ticket', [], req, listener);
    }

    demo_getMovieSchedules(movieId, day, listener) {
        this.get('/api/movie-demo/get-schedules', [movieId, day], listener);
    }

    demo_getSeatPlan(showtimeId, listener) {
        this.get('/api/movie-demo/get-seat-plan', [showtimeId], listener);
    }



}

export function createApiWithLang(lang) {
    const api = new Api();
    api.lang = lang;
    return api;
}

export function createApiWithHeaders(headers) {
    const api = new Api();
    api.headers = headers;
    return api;
}

export function createApi(listener) {
    const api = new Api();
    listener(api);
    return api;
}



export default new Api();